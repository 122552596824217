import Vue from "vue";
import { getSession, initRoutes } from "./methods";
// import { getToken } from "./auth";
import store from "../store";
import baseRoutes from "../router/baseRoutes";
import router from "../router";
import App from "@/App.vue";
import { allMenus } from "@/api/sysTem/menuAdmin";
import { eGetInboxEmailNums } from "@/api/emailApi";

import en from '../lang/en'
import zh from '../lang/zh'
let language = null
if(getSession('LanguageName') == 'en') {
	language = en
}else if(getSession('LanguageName') == 'zh-cn') {
	language = zh
}else {
	let lang = navigator.language; //浏览器语言判断
  lang = lang.substr(0, 2);
	switch (lang) {
		case 'zh': //中文
		language = zh;
				break;
		case 'en': //英文
		language = en;
				break;
		default:
			language = en;
			break;
	}
}

// 写死模块多语言配置
import i18n from './i18n.js'
import lang from './lang.js'
window.lang = lang
// if (getToken()) {
let vueThis = null
if(getSession('vue_admin_template_token')) {
  allMenus().then((res) => {
    let routes = res;
    routes.forEach(item => {
      if(item.title == '基础数据' || item.path == '/Basic_data' || item.path == '/basic_data') {
        item.children.unshift(
          {
            component: 'ReportModule',
            icon: 'icon-yunhangguanli',
            meta: {
              colorIcon: 'icon-xitongshu',
              icon: "icon-rizhiguanli",
              title: language.Menu.termsTemplete,
              keepAlive: true
            },
            hidden: false,
            name: 'ReportModule',
            path: 'ReportModule'
          },
          {
            component: 'Dict',
              icon: 'icon-dashuju',
              meta: {
                colorIcon: 'icon-xitongshu',
                icon: "icon-dashuju",
                title: language.Menu.dataDict,
                keepAlive: true
              },
              hidden: false,
              name: 'dict',
              path: 'dict'
          }
        )
        // item.children.unshift()
      }
    })
    // 判断邮件设置状态，控制邮件菜单显示内容
    let emailStatus = getSession('emailLogin') ? true : false,
        idx = baseRoutes.findIndex(f => f.name == 'Email');
    if(!emailStatus) {
      baseRoutes[idx].children = [{
        path: 'emailLogin',
        component: 'emailLogin',
        name: 'emailLogin',
        meta: {
          title: language.Emails.emailSet,
          icon: 'icon-icon-email'
        }
      }];
    } else {
      let unread = 0, draftsNums = 0, trashNums = 0;
      // 获取收件箱未读邮件数和草稿箱、垃圾箱的邮件数量
      eGetInboxEmailNums().then(t => {
        if(t.data.status) {
          unread = t.data.data.shoujian;
          draftsNums = t.data.data.caogao;
          trashNums = t.data.data.delcount;
        }
      }).finally(_ => {
        // 数量大于0时显示在菜单上
        baseRoutes[idx].children = [
          {
            path: 'emailInbox',
            component: 'emailInbox',
            name: 'emailInbox',
            meta: {
              title: unread > 0 ? `${language.Emails.inbox}(${unread})` : language.Emails.inbox,
              icon: 'icon-duanxin'
            }
          },
          {
            path: 'emailCompose',
            component: 'emailCompose',
            name: 'emailCompose',
            meta: {
              title: language.Emails.write,
              icon: 'icon-touxieyoujian'
            }
          },
          {
            path: 'emailContacts',
            component: 'emailContacts',
            name: 'emailContacts',
            meta: {
              title: language.Emails.contact,
              icon: 'icon-tongxunluchazhao'
            }
          },
          {
            path: 'emailSent',
            component: 'emailSent',
            name: 'emailSent',
            meta: {
              title: language.Emails.outbox,
              icon: 'icon-duanxin'
            }
          },
          {
            path: 'emailDrafts',
            component: 'emailDrafts',
            name: 'emailDrafts',
            meta: {
              title: draftsNums > 0 ? `${language.Emails.drafts}(${draftsNums})` : language.Emails.drafts,
              icon: 'icon-cunrucaogaoxiang'
            }
          },
          {
            path: 'emailSpam',
            component: 'emailSpam',
            name: 'emailSpam',
            meta: {
              title: trashNums > 0 ? `${language.Emails.trash}(${trashNums})` : language.Emails.trash,
              icon: 'icon-yishanchu'
            }
          },
          {
            path: 'emailMyFolders',
            component: 'emailMyFolders',
            name: 'emailMyFolders',
            meta: {
              title: language.Emails.folders,
              icon: 'icon-xiangmuguanli'
            }
          },
          {
            path: 'emailSettings',
            component: 'emailSettings',
            name: 'emailSettings',
            meta: {
              title: language.Emails.settings,
              icon: 'icon-shezhi1'
            }
          }
        ]
      })
    }

    let init = initRoutes(routes);
    router.addRoutes(init);
    store.dispatch("global/ROUTES_SET", [...baseRoutes, ...routes]);
    vueThis = new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  });
} else {
  vueThis = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}

let tags = getSession("tags");
if (tags) {
  store.dispatch("global/TAG_ADD", tags);
}

let user = getSession("userInfo");
if (user) {
  store.commit("global/GET_USERINFO", user);
}

export default vueThis;