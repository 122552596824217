<template>
  <div class="annexes">
    <el-upload
      class="upload-demo"
      drag
      :action=" baseUrl + '/common/upload'"
      :headers="headers"
      :show-file-list="false"
      multiple
      :data="resData"
      :on-success="onSuccess"
      :before-upload="onProgress"
    >
    <!-- on-progress -->
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">文件大小不超过1GB</div>
    </el-upload>
  </div>
</template>

<script>
import { getSession } from "@/utils/methods";
export default {
  name: "Y-annexes",
  data() {
    return {
      baseUrl: this.$baseUrl,
      headers: { Authorization: getSession("vue_admin_template_token") },
      resData: {
        attachType: 1,
        id: "",
      },
    };
  },
  props: {
    ids: {
      type: String,
      default: ""
    }
  },
   watch: {
    ids: {
      handler(newVal) {
        // console.log(newVal)
        this.$set(this.resData, "id", newVal)
        
      }
    }
  },
  created() {
    this.resData.id = this.ids
  },
  mounted() {},
  methods: {
    onSuccess () {
      this.$emit("onSuccessClcik")
    },
    onProgress (file) {
      if(file.size/1024/1024/1024 >= 1) {
        this.$message.error('文件大小不能超过1GB');
        return
      }
      let FileName = file.name.split(".")
      let newFileName = FileName[1]
      console.log("resData", this.resData)
      if (newFileName == "jpg" || newFileName == "jpeg" || newFileName == "jpe" || newFileName == "png" || newFileName == "pns") {
        this.resData.attachType = 10
      } else { 
        this.resData.attachType = 1
      }
      // return false
      // this.$emit("onProgress")
    }
  },
};
</script>

<style lang="scss" scoped>
.annexes {
    padding: 50px 80px;
    .upload-demo {
        width: 360px;
        .el-upload__text,
        .el-upload__tip {
            font-size: 18px;
        }
        .el-upload__tip {
            text-align: center;
        }
    }
}
</style>