var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-collapse",
    {
      attrs: { accordion: _vm.accordion },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    _vm._l(_vm.collapse, function(item, index) {
      return _c(
        "el-collapse-item",
        {
          key: item.title + "-" + index,
          attrs: { title: item.title, name: item.title }
        },
        [_vm._t("default", null, { item: item, $index: index })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }