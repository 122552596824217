var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._b(
      {
        attrs: {
          title: _vm.title,
          visible: _vm.dialog,
          width: _vm.width,
          "append-to-body": "",
          "destroy-on-close": _vm.destoryOnClone,
          "close-on-click-modal": false
        },
        on: {
          close: function($event) {
            return _vm.$emit("update:dialog", false)
          }
        }
      },
      "el-dialog",
      _vm.$attrs,
      false
    ),
    [
      _vm._t("default"),
      _vm._t("footer", [
        _c(
          "span",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer"
          },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini" },
                on: {
                  click: function($event) {
                    return _vm.$emit("update:dialog", false)
                  }
                }
              },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary" },
                on: {
                  click: function($event) {
                    return _vm.$emit("handleOk", _vm.$slots.default)
                  }
                }
              },
              [_vm._v("确 定")]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }