export default {
  tags: [
    {
      fullPath: "/home",
      hash: "",
      meta: { title: "首页", icon: null },
      name: "home",
      params: {},
      path: "/home",
      query: {},
      title: "首页",
    },
  ],
  routes: [],
  userInfo: {},
  tagCloseRouteList:[], //标签关闭的路由(列表页)
  tagCloseDetailRouteList:[], //标签关闭的路由(详情页)
  moduleMainFormData:null, //模块之间共享主模块表单数据、用于子表调取界面实时获取当前所处主表单上的最新值作为参数传递
  isClickTag: 1  // 1代表是点击Y-tag有需要就缓存，0是代表点击得table得操作列不缓存
};
