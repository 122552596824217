var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-body" },
    [
      _c(
        "el-input",
        {
          staticStyle: { position: "relative" },
          attrs: { clearable: "", placeholder: "请输入图标中文名称或英文名称" },
          on: { clear: _vm.filterIcons, input: _vm.filterIcons },
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        },
        [
          _c("i", {
            staticClass: "el-icon-search el-input__icon",
            attrs: { slot: "suffix" },
            slot: "suffix"
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "content font-class" },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "单色图标", name: "first" } },
                [
                  _c(
                    "ul",
                    { staticClass: "icon_lists dib-box" },
                    _vm._l(_vm.iconList, function(item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "dib",
                          on: {
                            click: function($event) {
                              return _vm.selectedIcon(item.icon)
                            }
                          }
                        },
                        [
                          _c("span", { class: "icon iconfont " + item.icon }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("div", { staticClass: "code-name" }, [
                            _vm._v(_vm._s(item.icon))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "多色图标", name: "second" } },
                [
                  _c(
                    "ul",
                    { staticClass: "icon_lists dib-box" },
                    _vm._l(_vm.colorIconList, function(item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "dib",
                          on: {
                            click: function($event) {
                              return _vm.selectedIcon(item.icon)
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "icon svg-icon",
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#" + item.icon }
                              })
                            ]
                          ),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("div", { staticClass: "code-name" }, [
                            _vm._v(_vm._s(item.icon))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }