var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor" }, [
    _c("div", {
      ref: "demo2",
      attrs: { id: "demo2" },
      domProps: { innerHTML: _vm._s(_vm.editorData) },
      on: { input: _vm.input }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }