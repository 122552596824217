import Vue from 'vue';
import axios from 'axios';
import { Message,MessageBox } from 'element-ui';
// import { getToken } from "@/utils/auth";
import { getSession, removeSession} from '@/utils/methods';
import { cancelApi, myLoading } from '@/utils/myApi';
import { Promise } from 'core-js';
let Loading = new myLoading('#app');
let CancelApi = new cancelApi();
Vue.prototype.CancelApi = CancelApi;
let baseURL = process.env.VUE_APP_BASE_API;
let serverpage = "/serverpage"
if (baseURL === '/pro') {
	// baseURL = 'http://60.174.231.106:7207' //昇维BS
	// baseURL = 'http://60.174.231.106:7307' //昇维BS测试
    baseURL = window.baseURL;
	//baseURL = 'http://47.106.189.19:8877' //育贸通BS
	//baseURL = 'http://47.106.189.19:9999' //育贸通BS测试
	//baseURL = 'http://121.5.216.151:9773/' // 四川荣氏
	// baseURL = 'http://114.116.11.77:9885/' // 公司测试
    // baseURL = window?.config?.baseurl
    serverpage = window.serverpage //公司演示
	//serverpage = 'http://47.106.189.19:8866/serverpage' // 育贸通
	//serverpage = 'http://47.106.189.19:7777/serverpage' // 育贸通测试
	// serverpage = 'http://60.174.231.106:6201/serverpage' // 昇维
	// serverpage = 'http://60.174.231.106:6301/serverpage' // 昇维测试
	//serverpage = 'http://121.5.216.151:9513/serverpage' // 四川荣氏
	// serverpage = 'http://114.116.11.77:9887/serverpage' // 公司测试
}
Vue.prototype.$baseUrl = baseURL
Vue.prototype.$serverpage = serverpage
const server = axios.create({
	baseURL: baseURL,
	timeout: 50000,
});

server.interceptors.request.use(
	config => {
		CancelApi.remove(config);
		CancelApi.add(config);
		// config.headers.Authorization = getToken() || "";
		config.headers["Accept-Language"] = getSession('LanguageName') || 'zh-cn';
		config.headers.Authorization = getSession('vue_admin_template_token') || '';
		if (config.url === '/route/route') {
			Loading.start('正在初始化请稍等...');
		} else {
			// Loading.start("喝杯茶稍等一下啵");
			// Loading.start("数据加载中...");
		}
		return config;
	},
	err => {
		console.error('error://', err);
		return Promise.reject(err);
	}
);

server.interceptors.response.use(
	response => {
		Vue.prototype.$jsons = response;
		if(response.config.url.indexOf('/sys/user/') != -1) {
		}else {
			CancelApi.remove(response);
		}
		Loading.end();
		if (
			(typeof response.data == 'string' || response.data instanceof Blob) &&
			response.status == 200
		) {
			return response;
		}
		// 判断鉴权
		if(response.config.url == '/activiti/hasRevocation') {
			return response.data
		}
		const res = response.data;
		if (!res.status) {
			if(res.code == 500 && res.message == '该列表配置已被删除') {
				Message.error("此模块未启用");
				return;
			}
			Message.error({
				message: res.message || 'Error',
				type: 'error',
				duration: 3000,
				customClass: 'c-el-message'
			});
			if (res.code == 400) {
				return res.data;
			}
			if (res.code == 401) {
				MessageBox.alert((res.message || '未授权') + ',点击确认后重新登录','温馨提示', {
					confirmButtonText: '确定',
					callback: action => {
						// token过期
						removeSession("vue_admin_template_token");
						removeSession("tags");
						removeSession("hxemail_token");
						removeSession("uid");
						removeSession("users");
						removeSession("headerCollapse");
						removeSession("listTreeCollapse");
						removeSession("emailLogin");
						window.location = window.location.origin;
					}
				  });
				
			}
			return Promise.reject(new Error(res.message || 'Error'));
		}

		// res.message &&
		//   MessageMessage.success({
		//     message: res.message,
		//     duration: 1000,
		//   });
		return res.data;
	},
	err => {
		Loading.end();
		if (!axios.isCancel(err)) {
			// console.error('error://', err);
			// Message.error({
			// 	message: err.message,
			// 	duration: 4000,
			// });
		}
		let promise = new Promise(() => {
			setTimeout(() => {
				// promise.cancel();
			});
		});
		return promise;
	}
);

export default server;
