import request from "@/axios/index";
export function renderDom(id) {
  return request({
    url: "/custom/selectConfigs?configId=" + id,
    method: "get",
  });
}

export function renderData({ data = {}, params = {} }) {
  return request({
    url: "/custom/selectList",
    method: "post",
    params,
    data,
  });
}

// 归档
export function placeOnFiledata(data, params) {
  return request({
    url: "/custom/archive",
    method: "post",
    params,
    data,
  });
}

export function renderDatalist({ data = {}, params = {} }) {
  return request({
    url: "/custom/selectList",
    method: "post",
    params,
    data,
  });
}
export function exportData(id, data, lessID) {
  return request({
    url: `/custom/export?configId=${id}&productParentTypeId=${lessID}`,
    method: "post",
    responseType: 'blob',
    data,
  });
}
// 刷新存储过程
export function refreshData(id) {
  let count = Math.ceil(Math.random()*10)
  return request({
    url: `/custom/refresh?` + count + `&configId=${id}`,
    method: "post"
  });
}
// 导入
export function importData(id, data) {
  return request({
    url: `custom/import?configId=${id}`,
    method: "post",
    responseType: 'blob',
    data,
  });
}

// 调取商品类别列表
export function getProductCategoryList() {
  return request({
    url: "/productCategory/tree_select",
    method: "get",
    params: {},
  })
}

//添加商品类别
export function addProductCategory(params) {
  return request({
    url: "/productCategory",
    method: "post",
    data: params,
  })
}

//添加商品类别
export function zhuanDan(params) {
  return request({
    url: "/hai_hua/zhuan_dan",
    method: "post",
    data: params,
  })
}

//修改商品类别
export function editProductCategory(params, id) {
  return request({
    url: `/productCategory/${id}`,
    method: "PUT",
    data: params,
  })
}

//修改数据类别
export function editshujuCategory(params) {
  return request({
    url: '/productCategory/product_cat',
    method: "PUT",
    data: params,
  })
}

//删除商品类别
export function delProductCategory(id) {
  return request({
    url: `/productCategory/deleteAllById?id=${id}`,
    method: "DELETE"
  })
}

// 库存明细的占用功能
export function employ(fno, idList) {
  return request({
    url: `/stock/use?fno=${fno}`,
    method: "post",
    data:idList
  })
}

// 新-库存占用
export function occupy(params, data) {
  return request({
    url: '/purchasePlanStock/use',
    method: "post",
    params,
    data
  })
}

// 新-库存占用（批量）
export function occupyBatch(params, data) {
  return request({
    url: '/purchasePlanStock/batch_use',
    method: "post",
    params,
    data
  })
}

// 新-取消占用
export function cancelOccupy(data) {
  return request({
    url: '/purchasePlanStock/un_use',
    method: "post",
    data
  })
}

// 占用明细取消占用功能
export function unEmploy(idList) {
  return request({
    url: `/stock/un_use`,
    method: "post",
    data:idList
  })
}


// 获取打印URL
export function getPrintUrl(data) {
  return request({
    url: '/sys/report/get/url',
    method: "post",
    data,
  })
}

// 获取抬头章
export function getFours(val) {
  return request({
    url: '/addrCtrl/findListByFours?fours=' + val,
    method: "get"
  })
}

// 用户管理同步按钮
export function getSynchronousAssignmentGroup(flag) {
  return request({
    url: '/sys/user/syncAllUserGroup',
    method: "get",
    params: flag,
  })
}

// 占用明细同步更新fcat=-1的数据，qty也需要同步修改
export function updateQty(id) {
  return request({
    url: `/purchasePlanStock/sync?sourceId=${id}`,
    method: "get"
  })
}

// 采购计划-转单
export function toTransOrder(data) {
  return request({
    url: '/purchasePlan/transferOrder',
    method: "post",
    data
  })
}