var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.data,
      "show-checkbox": "",
      "node-key": _vm.path,
      "default-checked-keys": _vm.checkKeys,
      "expand-on-click-node": false,
      "render-content": _vm.renderContent
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }