<template lang="html">
  <div class="editor">
    <div :id="id" v-model="editorData" :ref="id" @input="input">
    </div>
    <!-- <button type="button" class="btn" @click="getEditorData">获取当前内容</button>
    <textarea name="" id="" cols="170" rows="20" readonly v-model="editorDataedsa"></textarea> -->
  </div>
</template>

<script>
import wangEditor from 'wangeditor'
import { getSession } from "@/utils/methods"
export default {
  name: 'editoritem',
  data() {
    return {
      ids: "",
      baseUrl: this.$baseUrl,
    }
  },
  props: {
    editorData: {
      type: String
    },
    fartherid: {
      type: String
    },
    id: {
      type: String
    },
  },
  watch: {
    // 监听父组件穿过来的数据，数据改变后刷新当前组件数据。
    fartherid: {
      handler(newVal) {
        this.ids = newVal
      }
    }
  },
  mounted() {
    this.initEditor()
  },
  methods: {
    input (e) {
       // 数据改变事件，改变之后将数据发送到父组件
      let jsonHtml = convertHtmlToRtf(e.target.outerHTML)
      // console.log(e.target.innerHTML.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' '))
      console.log(jsonHtml)
      this.$emit('input', e.target.innerHTML)
      function convertHtmlToRtf(html) {
        if (!(typeof html === "string" && html)) {
            return null;
        }
        var tmpRichText, hasHyperlinks;
        var richText = html;
        // Singleton tags
        richText = richText.replace(/<(?:hr)(?:\s+[^>]*)?\s*[\/]?>/ig, "{\\pard \\brdrb \\brdrs \\brdrw10 \\brsp20 \\par}{\\pard\\par}");
        richText = richText.replace(/<(?:br)(?:\s+[^>]*)?\s*[\/]?>/ig, "{\\pard\\par}");
        // Empty tags
        richText = richText.replace(/<(?:p|div|section|article)(?:\s+[^>]*)?\s*[\/]>/ig, "{\\pard\\par}");
        richText = richText.replace(/<(?:[^>]+)\/>/g, "");
        // Hyperlinks
        richText = richText.replace(/<a(?:\s+[^>]*)?(?:\s+href=(["'])(?:javascript:void\(0?\);?|#|return false;?|void\(0?\);?|)\1)(?:\s+[^>]*)?>/ig, "{{{");
        tmpRichText = richText;
        richText = richText.replace(/<a(?:\s+[^>]*)?(?:\s+href=(["'])(.+)\1)(?:\s+[^>]*)?>/ig, "{\\field{\\*\\fldinst{HYPERLINK \"$2\"}}{\\fldrslt{\\ul\\cf1");
        hasHyperlinks = richText !== tmpRichText;
        richText = richText.replace(/<a(?:\s+[^>]*)?>/ig, "{{{");
        richText = richText.replace(/<\/a(?:\s+[^>]*)?>/ig, "}}}");
        // Start tags
        richText = richText.replace(/<(?:b|strong)(?:\s+[^>]*)?>/ig, "{\\b");
        richText = richText.replace(/<(?:i|em)(?:\s+[^>]*)?>/ig, "{\\i");
        richText = richText.replace(/<(?:u|ins)(?:\s+[^>]*)?>/ig, "{\\ul");
        richText = richText.replace(/<(?:strike|del)(?:\s+[^>]*)?>/ig, "{\\strike");
        richText = richText.replace(/<sup(?:\s+[^>]*)?>/ig, "{\\super");
        richText = richText.replace(/<sub(?:\s+[^>]*)?>/ig, "{\\sub");
        richText = richText.replace(/<(?:p|div|section|article)(?:\s+[^>]*)?>/ig, "{\\pard");
        // End tags
        richText = richText.replace(/<\/(?:p|div|section|article)(?:\s+[^>]*)?>/ig, "\\par}");
        richText = richText.replace(/<\/(?:b|strong|i|em|u|ins|strike|del|sup|sub)(?:\s+[^>]*)?>/ig, "}");
        // Strip any other remaining HTML tags [but leave their contents]
        richText = richText.replace(/<(?:[^>]+)>/g, "");
        // Prefix and suffix the rich text with the necessary syntax
        richText = "{\\rtf1\\ansi" + (hasHyperlinks ? "{\\colortbl;\\red0\\green0\\blue255;}" : "") + richText +  "}";
        return richText;
      }
    },
    initEditor () {
       // 创建一个editor实例，并将它挂载到，上面的div上，这样这个div就变成了富文本编辑器
      const editor = new wangEditor(this.$refs[this.id])
      editor.config.uploadImgServer = this.baseUrl + '/common/upload'
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png',]
      editor.config.uploadImgParams = {
          type: "general",
          attachType: 10,
          id: "",
      }
      editor.config.uploadImgHeaders = {
        Authorization: getSession("vue_admin_template_token")
      }
      editor.config.uploadFileName = 'file'
      this.$watch('fartherid', () => {
        editor.config.uploadImgParams.id = this.fartherid
      })
      // editor.config.uploadImgParamsWithUrl = true
      editor.config.height = 650
      editor.config.uploadImgHooks = {
        fail: function(xhr, editor, res) {
        },
        success: function(xhr, editor, res) {
        },
        // 例如后端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function(Fn, res) {
          Fn(res.message)
        }
      }
      editor.create()
       // 将父组件中v-model所绑定的变量传递给value，再将value赋值给编辑器的内容。
      editor.txt.html(this.editorData)  
       // 监听value，因为数据流是向下的传递的，父组件v-model绑定的属性一变化，那么value就会变化，从而获取到最新的值，更新编辑器中的内容。
      this.$watch('editorData', () => {  
        editor.txt.html(this.editorData)
      })
    },
    getEditorData() {
      // 通过代码获取编辑器内容
      let data = this.editor.txt.html()
      this.editorDataedsa = data
    },
    beforeDestroy() {
      // 调用销毁 API 对当前编辑器实例进行销毁
      this.editor.destroy()
      this.editor = null
    }
  }
}
</script>

<style lang="scss">
  .editor {
    width: 100%;
    height: 100%;
    margin: auto;
    position: relative;
    z-index: 10;
  }
</style>