export function align() {
	return [
		{ value: 1, label: '左对齐' },
		{ value: 2, label: '居中对齐' },
		{ value: 3, label: '右对齐' },
	];
}
export function dataType() {
	return [
		{ value: 1, label: 'string' },
		{ value: 2, label: 'number' },
		{ value: 3, label: 'date' },
		{ value: 4, label: 'decimal' },
		{ value: 5, label: 'text' },
		{ value: 6, label: 'switch' },
	];
}
export function filterType() {
	return [
		{ value: 1, label: '等于' },
		{ value: 2, label: '小于' },
		{ value: 3, label: '小于等于' },
		{ value: 4, label: '大于' },
		{ value: 5, label: '大于等于' },
		{ value: 6, label: '全LIKE' },
		{ value: 7, label: '左LIKE' },
		{ value: 8, label: '右LIKE' },
		{ value: 9, label: 'BETWEEN' },
	];
}
export function dataForm() {
	return [
		{ value: 1, label: '数据库' },
		{ value: 2, label: '自定义表达式' },
	];
}
export function datePrecisionType() {
	return [
		{ value: 1, label: '年' },
		{ value: 2, label: '月' },
		{ value: 3, label: '日' },
		{ value: 4, label: '时' },
		{ value: 5, label: '分' },
		{ value: 6, label: '秒' },
	];
}
export function formType() {
	return [
		{ value: 1, label: '输入框' },
		{ value: 2, label: '日期范围选择框' },
		{ value: 3, label: '日期选择框' },
		{ value: 4, label: '下拉框' },
		{ value: 5, label: '开关' },
		{ value: 6, label: '文本域' },
		{ value: 7, label: '数字框' },
		{ value: 8, label: '富文本' },
		{ value: 9, label: '下拉复选框' },
		{ value: 10, label: '人员' },
		{ value: 11, label: '城市下拉框' },
		{ value: 12, label: '下拉输入框' },
	];
}
export function dataScope() {
	return [
		{ value: 1, label: '所有数据权限' },
		{ value: 2, label: '自定义数据权限' },
		{ value: 3, label: '本部门数据权限' },
		{ value: 4, label: '本部门及以下数据权限' },
		{ value: 5, label: '仅本人数据权限' },
	];
}
export function widthList() {
	return [
		{ value: 1, label: '拉伸' },
		{ value: 2, label: '平铺' },
		{ value: 3, label: '半拉伸' },
		{ value: 4, label: '占用三分之一' },
	];
}
export function pageSizes() {
	return [
		{ value: 20, label: '20' },
		{ value: 50, label: '50' },
		{ value: 100, label: '100' },
		{ value: 200, label: '200' },
		// { value: 300, label: '300' },
		// { value: 400, label: '400' },
	];
}
