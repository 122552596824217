var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-table-column", {
    attrs: {
      width:
        _vm.$route.path == "/cggl/zjjh_edit"
          ? 200
          : _vm.handleDisable
          ? _vm.headerWidth
          : this.$parent.$parent.handleWidth,
      align: "center",
      "class-name": "y-handle",
      fixed: "right"
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function(scope) {
          return [
            _vm._v(" " + _vm._s(_vm.handle) + " "),
            _vm.headerbr ? _c("br") : _vm._e(),
            _vm.isExcelImport
              ? _c("i", {
                  staticClass: "el-icon-upload2",
                  staticStyle: { "margin-left": "10px", cursor: "pointer" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("handleImport", {
                        e: $event,
                        scope: scope
                      })
                    }
                  }
                })
              : _vm._e(),
            _vm.isExcelExport
              ? _c("i", {
                  staticClass: "el-icon-download",
                  staticStyle: { "margin-left": "10px", cursor: "pointer" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("handleExport", {
                        e: $event,
                        scope: scope
                      })
                    }
                  }
                })
              : _vm._e()
          ]
        }
      },
      {
        key: "default",
        fn: function(scope) {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !scope.row.subtotal,
                    expression: "!scope.row.subtotal"
                  }
                ],
                staticClass: "table-handle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("handleClickHandle", {
                      e: $event,
                      scope: scope
                    })
                  }
                }
              },
              [
                _vm._l(_vm.handleBT, function(item, index) {
                  return [
                    _c(
                      "el-badge",
                      {
                        key: "badge" + index + item.key,
                        staticClass: "dot-item",
                        attrs: {
                          "is-dot":
                            item.key == "approvalHistory" &&
                            scope.row.newHistoryRecord
                        }
                      },
                      [
                        _c("i", {
                          class: item.class,
                          staticStyle: { "margin-right": "10px" },
                          attrs: { title: item.title, val: item.key }
                        })
                      ]
                    )
                  ]
                })
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }