var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "annexesList" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", "max-height": "450px" }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "80",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "realAttachName", label: "附件名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "attachPath", label: "附件路径" }
          }),
          _c("el-table-column", {
            attrs: { prop: "attachType", label: "附件类型", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "类型", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.type,
                          callback: function($$v) {
                            _vm.$set(scope.row, "type", $$v)
                          },
                          expression: "scope.row.type"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "普通附件", value: 1 }
                        }),
                        _c("el-option", {
                          attrs: { label: "采购合同", value: 2 }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", {
                      staticClass: "el-icon-download",
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(scope.$index, scope.row)
                        }
                      }
                    }),
                    _c("i", {
                      staticClass: "el-icon-delete-solid",
                      on: {
                        click: function($event) {
                          return _vm.handleDeletea(scope.$index, scope.row)
                        }
                      }
                    }),
                    _c("i", {
                      staticClass: "el-icon-view",
                      on: {
                        click: function($event) {
                          return _vm.handleView(scope.$index, scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("是否删除当前附件")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.dialogVisibleok }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }