import '@babel/polyfill';
import Vue from 'vue';
import store from './store';

import ElementUI from 'element-ui';
import com from '_com';
import dir from '@/directive';
import VXETable from 'vxe-table';

import '_utils/init';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/fonts/iconfont.css';
import './assets/fonts/iconfont.js';
import 'default-passive-events'
import '@/utils/drag.js'

import '@/styles/index.scss';
import 'vxe-table/lib/style.css'
import * as filters from './filter';
import _ from 'lodash';
import * as pro from '@/utils/prototype';
import './assets/icons'; // icon
import router from './router';
import { setSession } from '@/utils/methods';
// import Astrict from '@/utils/astrict'
// Vue.use(Astrict)

Object.entries(pro).forEach(item => {
	Array.prototype[item[0]] = item[1];
});

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key]);
});
router.beforeEach((to, from, next) => {
	// 动态title
	if(to.meta.title) {
		document.title = to.meta.title;
	}
	setSession('tagStep', from.fullPath);
	next();
});
Vue.prototype._ = _;
// Vue.prototype._CancelApi = CancelApi;
// Vue.use(CKEditor);
Vue.use(ElementUI);
Vue.use(VXETable)
Vue.use(com);
Vue.use(dir);
Vue.use(store);
Vue.prototype.$VXETable = VXETable
Vue.config.productionTip = false;
