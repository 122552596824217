var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vuequilleditor" },
    [
      _c("editor-bar", {
        attrs: {
          editorData: _vm.editorDataList,
          fartherid: _vm.fartherid,
          id: _vm.idSpan
        },
        on: { input: _vm.inputchange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }