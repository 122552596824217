var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { trigger: _vm.trigger }, on: { command: _vm.handleClick } },
    [
      _c("span", { staticClass: "el-dropdown-link" }, [_vm._t("default")], 2),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        _vm._l(_vm.dropdown, function(item) {
          return _c(
            "el-dropdown-item",
            {
              key: item.label,
              attrs: {
                command: item.command,
                icon: item.icon,
                divided: item.divided
              }
            },
            [_vm._v(_vm._s(item.label))]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }