export default {
  // 导航栏上方的操作栏
  user: {
    quit: '退出登录'
  },
  // 列表设置
  listOfSet: {
    add: '新增',
    clearCatch: '清除缓存',
    loadField: '加载字段',
    copyModule: '复制模块',
    search: "搜索",
    reset: "重置",
    edit: '编辑',
    delete:'删除',
    colWidth: '列宽',
    moduleName: '模块名称',
    mainTable: '主表',
    foreignKey: '外键',
    listSql: '列表sql',
    isImage: '图片',
    imageColumn:'图片第几列',
    storedProcedure: '表单存储过程',
    isReadOnly: '仅查看',
    isInlineEditable: '行内编辑',
    isUpdateKeyOnImport: '导入更新',
    isCheckFno: '校验fno唯一',
    parentModule: '上级模块',
    isPage:'分页',
    contactSql: '联系人sql',
    listStoredProcedure: '列表存储过程',
    checkStoredProcedure: '校验存储过程',
    isShowList: '列表展示',
    tableImgShow: '表格图片展示',
    formImgShow:'表单图片展示',
    isImport: '导入',
    isExport: '导出',
    isSendEmail: '发送邮件',
    isUploadFile: '上传附件',
    isBatchModify: '批量修改',
    isAboutTypography: '左右排版',
    isGlobalRetrieval: '全局检索',
    tableFieldSet: '表格字段设置',
    formFieldSet: '表单字段设置',
    languageSet: '语言设置',
    sheetSet: 'sheet页设置',
    isDbClkJump: '双击进入详情' ,
    isShowSubtotal: '分组小计',
    showTheApprovalTab: '显示审批页签',
    passProcName: '通过存储过程',
    rejectProcName: '驳回存储过程',
    pageSize: '默认每页显示数量',
    addSuccess: '新增成功',
    editSuccss: '更新成功',

    /* 字段设置 */
    fieldShowOrder: '字段显示顺序',
    databaseFieldName: '数据库字段名',
    showName: '前端显示名称',
    dataType: '数据类型',
    alignType: "对齐方式",
    queryOperator: "查询操作符",
    isSelect: '查询',
    isLocked: "锁定",
    isGlobalQuery: '全局检索',
    isSum: '合计',
    isShow: '可见',
    dataDict: '数据字典',
    datePrecision: "日期精度",
    language: '语言设置',
    databaseFields: '数据库字段名',

    //编码设置
    save: '保存',
    year: '年份(2位)',
    years: '年份(4位)',
    month: '月份',
    date: '日期',
    fixedContent1: '固定内容1',
    fixedContent2: '固定内容2',
    serialNumber: '流水号',
    loginId: '登录人ID',
    serialNoDigit: '流水号位数',
    serialNoUt: '单号单位',
    pattern: '格式',
    formField1: '表单字段1',
    formField2: '表单字段2',
    formField3: '表单字段3',
    formField4: '表单字段4',
    formField5: '表单字段5',
    smallSerialStart: '小流水起始编号',
    bigSerial: '大流水号',
    resetSuccess: '已重置'
  },

  FormOfSet: {
    add: '新增',
    fieldOrder: '字段显示顺序',
    name: '名称',
    showName: '显示名称',
    defaultValue: '新增默认值',
    expressionDecimalDigit: '保留位数',
    dataType: '数据类型',
    editType: '编辑类型',
    formConfigId: '表单配置Id',
    isMustFill: '必填',
    approveParam: '提交字段',
    approveChange: '审批更改字段',
    combinedField: '合计字段',
    majorKey: '主键',
    isShow: '可见',
    isReadOnly: '只读',
    dataDict: '数据字典',
    remark: '备注',
    sheet: '所属sheet页面',
    expression: '自定义表达式',
    clauseTemplete: '条款模板模型',
    widthType: '宽度类型',
  },

  // 配置模块
  configMoudle: {
    transferOrder: '转单',
    highSeasCustomers: '公海客户',
    formalCustomers: '正式客户',
    sendEmail: '发送邮件',
    excelCopy: 'Excel 复制',
    claim: '认领',
    add: '新增',
    copyTokey: '复制到表格',
    copyToform: '复制到表单',
    edit: '编辑',
    delete: '删除',
    bulkEdit: '批量修改',
    content: '请输入内容',
    selectDate: '选择日期',
    select: '请选择',
    commodityTypeAdmin: '商品类别管理',
    parentType: '上级类别',
    TypeName: '类别名称',
    clickUpload: '点击上传',
    fileView: '文件预览',
    Viewflie: '预览',
    save: '保存',
    printFormat: '打印格式',
    headSeal: '抬头章',
    cancel: '取消',
    print: '打印',
    globalQuery: '全局检索',
    modelName: '模板名',
    search: '查找',
    img: '图片',
    tip1: '请输入类别名称',
    tip2: '修改成功',
    tip3: '添加成功',
    tip4: '请选择一项',
    tip5: '确认要删除当前类别以及子类别吗',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip6: '删除成功',
    refresh: '刷新',
    view: '查看',
    versionView: '版本查看',
    tip7: '只能上传Excel格式',
    tip8: '您不是该单据的创建人',
    tip9: '该单据正在审批中或已通过',
    tip10: '您没有选择行',
    tip11: '请不要勾选已审批或正在审批的行',
    tip12: '请先选择一行再修改',
    tip13: '请先选择一行再打印',
    tip14: '请选择报表输出格式！',
    tip15: '请选择模块名！',
    tip16: '打印成功',
    tip17: '下载成功',
    approvalHistory:'审批历史',
    purchaseContract: '采购合同号',

    // 详情
    fetch: '调取',
    approval: '审批同意',
    reject: '审批驳回',
    approvalOption: '审批意见',
    sendEmail: '发送邮件',
    Examination: '审批',
    AnnextopurchaseContract: '采购合同附件',
    Appendixtoexportcontract: '外销合同附件',
    uploadFile: '上传附件',
    report: '报表',
    includeFetchSql: '含已调取',
    includeSqlFinish: '含已调取完毕数据',
    viewLargeImg: '查看大图',
    save: '保存',
    status: '状态',
    copy: '复制',
    topcopy: '上移',
    bottomcopy: '下移',
    moveRow: '移动行号',
    update: '保存',
    submit: '提交',
    recall: '撤回',
    list: '列表',
    copyInvoice: '复制单据',
    // tip17: '请选择内容再点击确定',
    tip18: '页面已刷新，请重新进入此页再执行该操作！',
    subTotalSet: '小计配置',
    openSubtotal: '开启小计',
    closeSubtotal: '关闭小计',
    groupCondition: '分组条件',
    subtotalField: '小计字段',
    notSelected:'未选择',
    selected:'已选择',
    goLeft:'到左边',
    goRight:'到右边',
    noFno: '未找到此合同号，无法跳转',

    // Y-common
    linenum: '行号',
    batchDelete: '批量删除',
    replaceLineNumber: '目的行号',
    tip19: '您输入得行号不合法',
    tip20: '审批状态中...',
    tip21: '请选择要删除的数据',
    tip22: '只能选择一条进行换行',
    cancelFilter: '取消筛选',    

    SalesContract: '外销合同',
    PurchaseContract: '采购合同',
    alteration: '变更',
    cancelAlteration: '取消变更'
  },


  // 列表设置-----语言设置
  LanguageSet: {
    add: "新增",
    language: '语言',
    content: '内容',
    operation: '操作',
    simplifiedChinese: "中文简体",
    confirm: "确定",
    cancel: "取消"
  },

  //列表设置-- sheet页设置
  SheetSetting: {
    name: '名称',
    AccordingToTheOrder: '显示排序',
    add: '新增'
  },

  // 调取设置
  FetchSet: {
    sequence: '顺序',
    add: '新增',
    name: '名称',
    targetModuleId: '目标模块Id',
    parentModuleId: '上级模块Id',
    targetField: '目标字段',
    colWidth: '字段列宽',
    isPaged: '分页',
    fetchSql: '调取语句',
    isShowImage: '显示图片',
    showColumns: '显示在第几列',
    isRetrieval: '启用',
    containsCalledSQL: '含已调取的Sql',
    edit: '编辑',
    delete: '删除',
    languageSetting: '语言设置',
    tableFiledSetting: '表格字段设置',
    fetchWarinning: '请填写调取语句!',
    cancel: '取消',
    confirm: '确定'
  },

  // 调取设置 ---- 调取字段设置
  FetchKeySet: {
    add: '新增',
    fieldShowOrder: '字段显示顺序',
    databaseFieldName: '数据库字段名',
    showName: '前端显示名称',
    dataType: '数据类型',
    alignType: "对齐方式",
    isSelect: '查询',
    queryOperator: "查询操作符",
    isSortable: '排序',
    isFetched: "调取",
    isLocked: "锁定",
    targetField: "目标字段",
    isShow: "可见",
    datePrecision: "日期精度",
  },

  // 首页设置
  homeSet: {
    homeSet: "首页设置",
    roleNamePlaceHolder: '请输入角色名称',
    warningSet: '预警设置',
    reportSet: '报表设置',
    earlyWarning: '预警',
    title: '标题',
    router: '路由',
    sql: 'sql',
    languageSetting: '语言设置',
    save: '保存',
    select: '请选择',
    inputPlaceHolder: '请输入内容',
    report: '报表',
    save: '保存',
    add: '新增',
    edit: '编辑',
    submitTip: '提交数据不能为空',
    saveTip: '保存成功',
    completeTip: '请完善数据并保存之后在设置语言',
    saveFailed: '保存失败',
    qrSetting: '二维码设置',
    qrUpload: 'APP二维码上传',
    qrEdit: '二维码信息编辑',
    qrPlaceholder: '请输入要编辑的信息',
    qrTips: '请先上传图片'
  },

  // 报表权限设置
  ReportSet: {
    jimuReportPermissionSetting: '积木报表权限设置',
    roleNamePlaceHolder: '请输入角色名称',
    save: '保存',
    code: '编号',
    reportName: '报表名称',
    createTime: '创建时间',
    warningTip: '请至少选择一项',
    saveTip: '保存成功',
    add: '新增',
    edit: '编辑',
  },

  // 字典设置
  DictSet: {
    searchButton: '搜索',
    resetButton: '重置',
    add: '新增',
    edit: '编辑',
    delete: '删除',
    dictName: '字典名称',
    isForbidden: '禁用',
    remark: '备注',
    dataItemSetting: '数据项设置',
    name: '名称',
    alias: '别名',
    sortOrder: '排列顺序',
    desc: '描述',
    dataPermission: '数据权限',
  },

  // 菜单管理
  menuSet: {
    menuName: '菜单名称',
    search: '搜索',
    reset: '重置',
    add: '新增',
    edit: '编辑',
    delete: '删除',
    icon: '图标',
    type: '类型',
    orderNum: '显示顺序',
    permsCode: '权限标识',
    remark: '备注',
    routingPath: '路由地址',
    menuStatus: '菜单状态',
    addMenu: '新增菜单',
    menuType: '菜单类型',
    menuIcon: '菜单图标(单色)',
    menuIconColor: '菜单图标多色',
    menuVisable: '菜单可见',
    menuOrder: '菜单顺序',
    parentCatalogue: '上级目录',
    associatedModule: '关联模块',
    buttonName: '按钮名称',
    language: '语言设置',
    action: '操作'
  },

  // 用户管理
  userAdmin: {
    deptPlace: '请输入部门名称',
    userName: '用户名称',
    password: '密码',
    loginAccount: '登录账号',
    phoneNumber: '手机号码',
    status: '状态',
    createTime: '创建时间',
    search: '搜索',
    reset: '重置',
    add: '新增',
    passOn: '转交',
    deptName: '部门名称',
    nickName: '用户昵称',
    englishName: '英文名称',
    phone: '手机号码',
    email: '用户邮箱',
    roleName: '角色',
    sex: '性别',
    postId: '岗位Id',
    avatar: '头像',
    deptId: '部门Id',
    passoword: '密码',
    remark: '备注',
    dept: '部门',
    edit: '编辑',
    delete: '删除',
    tip1: '请选择一项进行修改',
    loadingtip: '加载中，请稍等...',
    tip2: '请选择一项进行删除',
    tip3: '确认删除吗',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    deleteSuccess: '删除成功！',
    editSuccess: '修改成功',
    noEmpty: '不能为空',
    limitOfAuthority: '权限',
    synchronousAssignmentGroup: '同步赋值组别',
  },
  // 用户管理 --- 编辑用户
  editUserAdmin: {
    save: '保存',
    sync: '同步',
    add: '新增',
    userInfo: '用户信息',
    nickName: '用户昵称',
    englishName: '英文名称',
    password: '密码',
    phoneNumber: '手机号码',
    remark: '备注',
    loginAccount: '登录账号',
    userEmail: '用户邮箱',
    postId: '岗位Id',
    dept: '部门',
    roleName: '角色',
    superiorLeaders: '上级领导',
    sex: '性别',
    status: '状态',
    emailConfig: '邮件配置',
    dataPermission: '数据权限',
    modelName: '模块名称',
    groupName: '组名称',
    groupCode: '组代码',
    edit: '编辑',
    delete: '删除',
    loadingtip: '加载中，请稍等...',
    saveSuccess: '保存成功',
    emailUserName: '发件人姓名',
    emailPassword: '邮箱密码',
    smtpHost: 'SMTP地址',
    smtpPort: 'SMTP端口',
    signature: '签名',
    ownPhone: '私人手机号',
    qq: 'QQ' ,
    weChat: '微信' ,
    platformName: '平台名称' ,
    idcard: '身份证号' ,
    isEmailManage: '是否邮箱管理员'
  },


  // 在线用户
  userOnline: {
    userName: '用户名',
    name: '中文名',
    search: '搜索',
    reset: '重置',
    kickOut: '踢出',
    email: '邮箱',
    phone: '电话',
    sex: '性别',
    loginConputerIp: '登录计算机Ip',
    loginTime: '登录时间',
    nowTime: '登录时长',
    status: '状态',
    remark: '备注',
    tip1: '确认踢出此用户吗？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip2: '请先选择再踢出！',
    tip3: '已踢出用户'
  },


  // 角色管理
  roleAdmin: {
    tip1: '确定删除勾选的角色？',
    menuAllot: '菜单分配',
    roleName: '角色名称',
    status: '状态',
    search: '搜索',
    reset: '重置',
    add: '新增',
    save: '保存',
    showSort: '显示顺序',
    roleStatus: '角色状态',
    permissionChar: '权限字符',
    permissionScope: '权限范围',
    dataPermission: '数据权限',
    dataScope: '数据范围',
    remark: '备注',
    edit: '编辑',
    delete: '删除',
    tip2: '不能为空',
    tip3: '请先选择再删除',
    tip4: '请选择要修改分配的菜单',
    tip5: '保存成功'
  },

  // 部门管理
  deptAdmin: {
    deptName: '部门名称',
    status: '状态',
    search: '搜索',
    reset: '重置',
    add: '新增',
    deptCode: '部门代码',
    departmentHead: '部门负责人',
    contactWay: '联系方式',
    showSort: '显示顺序',
    createTime: '创建时间',
    addDept: '新增部门',
    superiorDepartment: '上级部门',
    edit: '编辑',
    delete: '删除',
    tip1: '确认删除勾选部门吗？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip2: '请先选择再删除！',
    tip3: '删除成功'
  },

  // 部门管理
  deptAdmin: {
    deptName: '部门名称',
    status: '状态',
    search: '搜索',
    reset: '重置',
    remark: '备注',
    add: '新增',
    deptCode: '部门代码',
    departmentHead: '部门负责人',
    contactWay: '联系方式(手机号)',
    showSort: '显示顺序',
    createTime: '创建时间',
    addDept: '新增部门',
    superiorDepartment: '上级部门',
    edit: '编辑',
    delete: '删除',
    tip1: '确认删除勾选部门吗？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip2: '请先选择再删除！',
    tip3: '删除成功'
  },

  //打印管理
  printAdmin: {
    add: '新增',
    modelNumber: '模块序号',
    modelCode: '模块代码',
    modelId: '模块Id',
    mainTableName: '主表名称',
    modelName: '模块名称',
    reportName: '报表名称',
    idField: 'id字段',
    remark: '备注',
    edit: '编辑',
    delete: '删除',
    tip1: '确认删除勾选模块吗？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip2: '请先选择再删除',
    tip3: '删除成功',
  },
  // 系统日志
  Systemlog: {
    tip: '提示',
    requestParams: '请求参数',
    returnParams: '返回参数',
    module: '模块',
    status: '状态',
    message: '消息',
    requestMethodName: '请求方法名称',
    requestIp: '请求Ip',
    requestWay: '请求方式',
    bussinessType: '业务类型',
    cancel: '取消',
    confirm: '确定',
    search: '搜索',
    reset: '重置',
    add: '新增',
    delete: '删除',
    tip1: '确认删除勾选参数吗？',
    tip2: '请先选择再删除',
    tip3: 'deleteSuccess'
  },

  // 模板类型
  termsTemplete: {
    add: '新增',
    templeteName: '模板名称',
    templeteContent: '模板内容',
    content: '请输入内容',
    templeteType: '模板类型',
    createBy: '创建人',
    createTime: '创建时间',
    updateBy: '更新人',
    updateTime: '更新时间',
    selectDate: '选择日期',
    cancel: '取消',
    confirm: '确定',
    search: '搜索',
    reset: '重置',
    delete: '删除',
  },

  // 预警设置
  warning: {
    content: '请输入内容',
    select: '请选择',
    warningTitle: '预警标题',
    isPage: '分页',
    warningSql: '预警sql',
    CustomMenuName: '自定义菜单名称',
    remark: '备注',
    showOrder: '显示顺序',
    isShow: '可见',
    cancel: '关闭',
    confirm: '确定',
    add: '新增',
    edit:"编辑",
    batchDeletes: '批量删除',
    delete: '删除',
    languageSet: '语言设置',
    warningFieldSet: '预警字段设置',
    tip1: '请选择要删除的数据',
    tip2: 'addSuccess'
  },

  // 预警设置---预警字段设置
  warnFieldSet: {
    databaseName: '数据库字段名',
    search: '查找',
    fieldShowOrder: '字段显示顺序',
    datebaseField: '数据库字段名',
    showName: '前端显示名称',
    dataType: '数据类型',
    select: '请选择',
    alignType: '对齐方式',
    isSelect: '查询',
    QueryOperator: '查询操作符',
    isLock: '锁定',
    datePrecision: '日期精度',
    isShow: '可见',
    cancel: '取消',
    confirm: '确定',
    add: '新增',
    edit: '编辑',
    languageSet: '语言设置',
    tip1: '请选择要删除的数据',
    tip2: '新增成功',
  },


  // 流程模型
  flowModel: {
    name: '名称',
    search: '搜索',
    reset: '重置',
    add: '新增',
    flowKey: '流程Key',
    version: '版本',
    createTime: '创建时间',
    updateTime: '更新时间',
    metadata: '元数据',
    edit: '编辑',
    delete: '删除',
    upload: '部署',
    download: '导出',
    downLoad: '下载',
    addFlowModel: '添加流程模型',
    category: '分类',
    desc: '描述',
  },

  // 流程定义
  flowDefinition: {
    operation: '操作',
    moduleId: '模块id',
    moduleName: '模块名称',
    programmeId:'方案id',
    programmeName:'方案名称',
    processDefinitionKey: '流程实例Key',
    remark: '备注',
    tip4: '确认删除勾选模块吗？',
    tip5: '请先选择再删除',
    tip6: '保存成功',
    save: '保存',
    active: '激活',
    hangUp: '挂起',
    transferModel: '转模型',
    delete: '删除',
    upload: '部署',
    category: '分类',
    select: '请选择',
    selectFile: '选择文件',
    clickUpload: '点击上传',
    cancel: '取消',
    confirm: '确定',
    flowChart: '流程图',
    flowKey: '流程Key',
    flowName: '流程名称',
    search: '搜索',
    reset: '重置',
    flowId: '流程ID',
    version: '版本',
    flowDesc: '流程描述',
    uploadTime: '部署时间',
    flowDefinition: '流程定义',
    deploymentProcessDefinition: '部署流程定义',
    flowDefinitionStatus: '流程定义状态',
    edit: '编辑',
    delete: '删除',
    actived: '已激活',
    tip1: '确认删除此流程吗？',
    tip: 'Tip',
    confirm: '确定',
    cancel: '取消',
    tip2: '删除成功',
    tip3: '请选择分类再提交',
    add: '新增'
  },

  // 审批方案
  programmeSet: {
    name: '名称',
    search: '搜索',
    reset: '重置',
    add: '新增',
    programmeSetAdd:'新增方案',
    programmeSetEdit: '编辑方案',
    programmeSetName: '方案名称',
    programmeSetCode: '方案编号',
    approvalNode: '审批节点',
    version: '版本',
    createTime: '创建时间',
    updateTime: '更新时间',
    metadata: '元数据',
    edit: '编辑',
    delete: '删除',
    upload: '部署',
    download: '导出',
    addFlowModel: '添加',
    category: '分类',
    desc: '描述',
  },


  // 审批
  Approval: {
    operation: '操作',
    agree: '同意',
    reject: '驳回',
    history: '历史',
    schedule: '进度',
    view: '查看',
    confirm: '确定',
    cancel: '取消',
    approvalOption: '审批意见',
    approvalHistory: '审批历史',
    approveParam: '审批参数',
    scheduleChart: '进度图',
    initiator: '发起人',
    moduleName: '模块名称',
    search: '搜索',
    reset: '重置',
    refresh: '刷新',
    approvalAgree: '审批通过',
    approvalReject: '审批驳回',
    approvalTime: '审批时间',
    // assignee: '分配给',
    approvalParam: '审批参数',
    taskName: '任务名称',
    assignee: '审批人',
    startTime: '开始时间',
    endTime: '结束时间',
    durationTime: '耗时',
    refreshSuccess: '刷新成功',
    refressFail: '刷新失败',
    tip1: '请勾选后再进行同意操作！',
    tip2: '请勾选后再进行驳回操作！',
    viewInvoices: '查看',
    print: '打印',
    approvalStatus: '审批状态',
    tip44: '请填写审批意见',
    initiationDate: '发起日期',
    fno: '单据编号',
    save: '保存'
  },

  // 订单进度管理
  Order: {
    orderProgressTable: '订单进度表',
    progress: '进度',
    operation: '操作',
    viewDetail: '查看详情',
    orderProgressChart: '订单进度图',
    contractNumber: '合同号',
    contractDate: '合同日期',
    customerName: '客户名称',
    search: '搜索',
    reset: '重置',
    refresh: '刷新',
    deliveryDate: '交货日期',
    destinationPort: '目的港',
    shipmentPort: '装运港',
    createBy: '监理人',
    progress: '进度',
    addNode: '新增节点',
    confirmNode: '确认节点',
    linkName: '环节名称',
    computeMode: '计算方式',
    basisNode: '依据节点',
    Days: '所需天数',
    createDate: '创建日期',
    chargePersion: '责任人',
    status: '状态',
    edit: '编辑',
    delete: '删除',
    selectNode: '选择节点',
    confirm: '确认',
    cancel: '取消',
    sendSuccess: '发送成功',
    addNodes: '新增节点库',
    nodeName: '节点名称',
    nodeEndCondition: '节点结束条件',
    nodeEndConditionSql: '节点结束条件语句',
    nodesName: '节点库名称',
    createByName: '创建人名称',
    createTime: '创建时间',
    tip1: '确认删除此节点吗？',
    tip: '提示',
    saveSuccess: '保存成功',
  },

  /* 左侧菜单 */
  Menu: {
    messageCenter: '消息中心',
    nodeSettings: '节点设定',
    businessFlowTracking: '业务流跟踪',
    SystemAdmin: '系统管理',
    ListSet: '列表设置',
    FetchSet: '调取设置',
    HomeSet: '首页设置',
    home: '首页',
    ReportPermissSet: '报表权限设置',
    dataDict: '数据字典',
    category: '分类字典',
    menuAdmin: '菜单管理',
    userAdmin: '用户管理',
    userOnline: '在线用户',
    roleAdmin: '角色管理',
    deptAdmin: '部门管理',
    printAdmin: '打印管理',
    systemLog: '系统日志',
    termsTemplete: '条款模板',
    warningSet: '预警设置',
    fieldSet: '字段设置',
    dataDictSet: '数据字典项设置',
    fetchFieldSet: '调取字段设置',
    sheetSet: 'sheet页设置',
    formSet: '表单设置',
    codeSet: '编码设置',
    editUser: '编辑用户',
    languageSet: '语言设置',
    versionView: '版本查看',
    persionCenter: '个人中心',
    warnPermissSet: '预警权限设置',
    warning: '预警',
    warningFieldSet: '预警字段设置',

    // 流程菜单
    flowMenu: '流程菜单',
    flowDefinition: '流程定义',
    flowConfig: '流程配置',
    flowModel: '流程模型',
    modelOnlineDesign: '流程设计',
    addFlowConfig: '新增流程配置',
    editFlowConfig: '编辑流程配置',
    programmeSet:'方案设置',
    programmeSetAdd:'新增方案',
    programmeSetEdit:'编辑方案',

    // 审批管理
    approvalAdmin: '审批管理',
    peddingApproval: '待审批',
    approval: '已审批',
    Initiated: '已发起',
    CCRecord: '抄送记录',

    // 订单进度管理
    orderProgressAdmin: '订单进度管理',
    orderProgressList: '订单进度列表',
    orderNodes: '订单节点库',
    orderProgressDetail: '订单进度详情',
    statisticsReport: '统计报表',
  },
  // 嵌套BI系统
  Bisystem: {
    BiName: "统计查询"
  },
  StatisticalManagement: {
    StatisticalManagement: "统计管理",
  },
  // 邮件模块
  Emails: {
    eName: '邮件系统',
    write: '写邮件',
    contact: '通讯录',
    inbox: '收件箱',
    outbox: '发件箱',
    drafts: '草稿箱',
    trash: '垃圾箱',
    folders: '文件夹',
    label: '邮件标签',
    settings: '邮件设置',
    please: '请登录',
    login: '登录',
    loginsuccess: '登录成功',
    loginfailed: '登录失败',
    account: '账号',
    password: '密码',
    notnull: '账号或密码不能为空',
    cancel: '取消',
    go: '确定',
    register: '注册',
    regSuccess: '注册成功',
    khname: '单位名称',
    copy: '抄送',
    secret: '密送',
    addcopy: '添加抄送',
    addsecret: '添加密送',
    delcopy: '删除抄送',
    delsecret: '删除密送',
    addressee: '收件人',
    copyName: '抄送人',
    linkTips: '暂无联系人请前往通讯录添加或直接输入',
    theme: '主题',
    upload: '上传附件',
    clearFiles: '清空列表',
    numsTips: '目前已选中附件数：',
    sizeTips: '附件列表总大小为：',
    readReceipt: '已读回执',
    Sender: '发件人',
    pickSender: '请选择发件人',
    send: '发送',
    save: '存草稿',
    reset: '重置',
    addresseeEmpty: '收件人不能为空',
    themeEmpty: '主题不能为空',
    senderEmpty: '发件人不能为空',
    senderNull: '还没有发件人，请前往邮箱设置添加',
    textEmpty: '发件内容不能为空',
    sendSuccess: '发送成功',
    sendFail: '发送失败',
    saveSuccess: '保存草稿成功',
    saveFail: '保存草稿失败',
    refresh: '刷新',
    themeKeyword: '主题关键字',
    contentKeyword: '内容关键字',
    senderKeyword: '选择发送人',
    getAll: '全选',
    readStatus: '阅读状态',
    all: '全部',
    unread: '未读',
    read: '已读',
    receive: '接收',
    delete: '删除',
    emailNums: '邮件总数',
    emailUnread: '未读邮件',
    receiving: '接收中...',
    receiveFail: '接收失败',
    receiveSuccess: '接收成功',
    emailDetails: '点击查看邮件详情',
    delTips: '请选择后再删除',
    delSuccess: '删除成功',
    delFail: '删除失败',
    contactsList: '联系人列表',
    contactsManage: '联系人管理',
    categoryManage: '类别管理',
    categoryName: '类别名称',
    customerName: '客户名称',
    name: '姓名',
    customerCode: '客户代码',
    email: '邮箱',
    operation: '操作',
    categoryParent: '上级分类',
    phone: '电话',
    reply: '回复',
    replyAll: '回复全部',
    date: '时间',
    saving: '保存',
    pickCategory: '选择类别',
    edit: '修改',
    cateTips: '请选择一项',
    writeCategoryName: '请填写类别名称',
    nameMailTips: '请将姓名和邮箱补充完整' ,
    recoverySuccess: '恢复成功',
    recoveryFail: '恢复失败',
    newFolder: '新增文件夹',
    addFolderTips: '请输入文件夹名称',
    addSuccess: '新增成功',
    addFail: '新增失败',
    emailSetting: '邮箱设置',
    signSetting: '签名设置',
    folderSetting: '文件夹设置',
    displayName: '显示名称',
    senderName: '发件人姓名',
    emailAddress: '邮箱地址',
    sendingServer: '发送服务器',
    port: '端口',
    sendTime: '发送时间',
    originalMail: '原始邮件',
    receiveAll: '收取所有账号',
    receiveMailPick: '请选择要收取的账号',
    receiveMailTime: '请选择要收取的时间段',
    receiveEmail: '收取选项',
    emailNums: '邮件数量',
    relatedModules: '相关模块',
    moduleName: '模块名',
    fno: '单据号',
    selectReportFile: '选择报表附件', 
    uploaded: '已上传的报表附件',
    uploadSuccess: '上传报表附件成功',
    uploadFailed: '上传报表附件失败',
    selectModelName: '请选择模块名', 
    selectPrintFormat: '请选择打印格式' ,
    readTime: '阅读时间'  ,
    readAddress: '所在地区'  ,
    readCount: '阅读次数',
    readCount: '阅读次数',
    markAllAsRead: '全部标记为已读',
    markSelectedAsRead: '选中标记为已读',
    markAllAsReadTip: '确认要将所有邮件标记为已读吗',
    markSelectedAsReadtip: '确认要将选中的邮件标记为已读吗',
    markedSuccess: '标记成功',
    markedFail: '标记失败',
    moveto: '移至',
    emailForward: '转发',
    emailTemplate: '邮件模板',
    restore: '确认要恢复此邮件吗',
    emailSet: '邮箱配置'
  }
}