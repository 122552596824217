const Loyout = () => import("@/layout");
const Home = () => import("@/views/home");
const Route = () => import("@/views/set/route");
const Page = () => import("@/views/set/page");
const List = () => import("@/views/list");
const Detail = () => import("@/views/detail");
const DetailAdd = () => import("@/views/detail/add");
const DetailEdit = () => import("@/views/detail/edit");
const Login = () => import("@/views/login");
const ComponentList = () => import("@/views/list");
const ListSet = () => import("@/views/system/listSet/index");
const HomeSet = () => import("@/views/system/homeSet/index");
const JimuReportSet = () => import("@/views/system/jimuReportSet/index");
const FetchSet = () => import("@/views/system/fetchSet/index");
const DictItemSet = () => import("@/views/system/dict/itemSet");
const modelSet = () => import("@/views/model/modelSet/index");
const definitionSet = () => import("@/views/model/definitionSet/index");
const todoSet = () => import("@/views/backlogs/todoSet/index");
const flowConfiger = () => import("@/views/model/flowConfig/index");
const flowConfigerDtl = () => import("@/views/model/flowConfig/create");
const flowConfigerEdit = () => import("@/views/model/flowConfig/edit");
const programmeSet = () => import("@/views/model/programmeSet/index");
const createProgramme = () => import("@/views/model/programmeSet/create");
const editProgramme = () => import("@/views/model/programmeSet/edit");
const doingSet = () => import("@/views/backlogs/doingSet/index");
const KeySet = () => import("@/views/system/keySet/index");
const Dict = () => import("@/views/system/dict/index");
const Category = () => import("@/views/system/category/index");
const FetchKeySet = () => import("@/views/system/fetchKeySet/index");
const SheetSet = () => import("@/views/system/sheetSet/index");
const FormSet = () => import("@/views/system/FormSet/index");
const codingSet = () =>import("@/views/system/codingSet/index")
const menuAdmin = () => import("@/views/system/menuAdmin/index");
const userAdmin = () => import("@/views/system/userAdmin/index");
const printAdmin = () => import("@/views/system/printAdmin/index")
const editUserAdmin = () => import("@/views/system/userAdmin/edit")
const versionView = () => import("@/views/system/versionView/index")
const userOnline = () => import("@/views/system/userOnline/index")
const nodeSettings = () => import("@/views/system/nodeSettings/index")
const messageCenter = () => import("@/views/system/messageCenter/index")
const businessFlowTracking = () => import("@/views/system/businessFlowTracking/index")
const roleAdmin = () => import("@/views/system/roleAdmin/index");
const deptAdmin = () => import("@/views/system/deptAdmin/index")
const systemLog = () => import("@/views/system/systemLog/index")
const ReportModule = () => import("@/views/system/ReportModule/index")
const personalCenter = () => import("@/views/system/personalCenter/index");
const peddingApproval = () => import("@/views/approval/peddingApproval/index")
const successApproval = () => import("@/views/approval/successApproval/index")
const successInitiated = () => import("@/views/approval/successInitiated/index")
const successApprovalCopy = ()=> import("@/views/approval/successApprovalCopy/index")
const createModel = () => import("@/views/model/modelSet/create.vue")
const orderNode = () => import("@/views/order/node/index")
const orderTemplate = () => import("@/views/order/template/index")
const orderListDetail = () => import("@/views/order/node/detail.vue")
const statistical = () => import("@/views/Statistical/index")
const earlyWarningSetting = () => import("@/views/earlyWarningSetting/index.vue")
const earlyWarningPermissionSetting = () => import("@/views/earlyWarningPermissionSetting/index.vue")
const earlyWarning = () => import("@/views/earlyWarning/index.vue")
const earlyWarningFieldSetting = () => import("@/views/earlyWarningFieldSetting/index.vue")
const LanguageSet = () => import("@/views/system/LanguageSet")
const email = () => import("@/views/email/index.vue")
const emailInbox = () => import("@/views/email/inbox/index.vue")
const emailCompose = () => import("@/views/email/compose/index.vue")
const emailContacts = () => import("@/views/email/contacts/index.vue")
const emailDrafts = () => import("@/views/email/drafts/index.vue")
const emailSent = () => import("@/views/email/sent/index.vue")
const emailSpam = () => import("@/views/email/spam/index.vue")
const emailMyFolders = () => import("@/views/email/myFolders/index.vue")
const emailSettings = () => import("@/views/email/settings/index.vue")
const emailLogin = () => import("@/views/email/login/index.vue")
const biname = () => import("@/views/bisystem/index.vue")
const statisticalManagement = () => import("@/views/StatisticalManagement/index.vue")
export default {
  Loyout,
  Home,
  Route,
  Page,
  List,
  Detail,
  DetailAdd,
  DetailEdit,
  Login,
  ComponentList,
  SheetSet,
  ListSet,
  HomeSet,
  JimuReportSet,
  FetchSet,
  flowConfiger,
  modelSet,
  createModel,
  definitionSet,
  todoSet,
  doingSet,
  KeySet,
  FormSet,
  codingSet,
  menuAdmin,
  userAdmin,
  editUserAdmin,
  versionView,
  userOnline,
  nodeSettings,
  messageCenter,
  businessFlowTracking,
  roleAdmin,
  deptAdmin,
  systemLog,
  ReportModule,
  personalCenter,
  flowConfigerDtl,
  flowConfigerEdit,
  programmeSet,
  createProgramme,
  editProgramme,
  peddingApproval,
  successApproval,
  successInitiated,
  successApprovalCopy,
  FetchKeySet,
  Dict,
  Category,
  DictItemSet,
  printAdmin,
  orderNode,
  orderTemplate,
  orderListDetail,
  statistical,
  earlyWarningSetting,
  earlyWarningPermissionSetting,
  earlyWarning,
  earlyWarningFieldSetting,
  LanguageSet,
  email,
  emailInbox,
  emailCompose,
  emailContacts,
  emailDrafts,
  emailSent,
  emailSpam,
  emailMyFolders,
  emailSettings,
  emailLogin,
  biname,
  statisticalManagement
};
