import routers from '../router/routers';
import _ from 'lodash';
import path from 'path';
import { isString, isArray } from '@/utils/validate';
import { getUser } from "_api/sysTem/userAdmin";

export const getSession = name => {
	if(sessionStorage.vue_admin_template_token) {
		if(sessionStorage.vue_admin_template_token!=='undefined') {
			return JSON.parse(sessionStorage.getItem(name));
		}
	}
};
export const setSession = (name = '', data) => {
	if (sessionStorage) {
		let o = JSON.stringify(data);
		sessionStorage.setItem(name, o);
	}
};
export const removeSession = (name = '') => {
	if (sessionStorage) {
		sessionStorage.removeItem(name);
	}
};

/* 加载用户字体大小 */
export const checkFont = () => {
	let updatetime = getSession('updateTime'),
  		info = getSession('users');
  	if(updatetime || info) {
		let fonts = info?.fontSize,
    		fontf = info?.fontFamily,
			fontw = info?.fontWeight;
		switch(fontf) {
			case "默认":
				document.body.style.fontFamily = "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif";
				break;
			case "宋体":
				document.body.style.fontFamily = "宋体";
				break;
			case "楷体":
				document.body.style.fontFamily = "楷体";
				break;
			case "圆体":
				document.body.style.fontFamily = "圆体";
				break;
			case "诗简体":
				document.body.style.fontFamily = "诗简体";
			default:break;
		}
		switch(fonts) {
			case "default":
				fonts = "14px";
				break;
			case "middle":
				fonts = "16px";
				break;
			case "large":
				fonts = "18px";
				break;
			default:break;
		}
		if(fontw) document.body.style.fontWeight = fontw;
		return fonts
	} else {
		setTimeout(() =>{
			let userinfo = getSession('userInfo');
			const id = userinfo.user.id;
			getUser(id).then(res => {
				let fonts = res?.fontSize,
					fontf = res.fontFamily,
					fontw = res?.fontWeight;
				if(!getSession('users')) setSession('users', res);
				switch(fontf) {
					case "默认":
						document.body.style.fontFamily = "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif";
						break;
					case "宋体":
						document.body.style.fontFamily = "宋体";
						break;
					case "楷体":
						document.body.style.fontFamily = "楷体";
						break;
					case "圆体":
						document.body.style.fontFamily = "圆体";
						break;
					case "诗简体":
						document.body.style.fontFamily = "诗简体";
					default:break;
				}
				switch(fonts) {
					case "default":
						fonts = "14px";
						break;
					case "middle":
						fonts = "16px";
						break;
					case "large":
						fonts = "18px";
						break;
					default:break;
				}
				if(fontw == 'bold') document.body.style.fontWeight = fontw;
				return fonts
			})
		}, 150)
	}
}
export const sortAny = (arr, key, sort = 'asc', objFn) => {
	if (!arr || !key) return;
	let formatterObjFn = (a, b) => {
		let key = 'formatter';
		if (!b[key] && b.prop === 'date') {
			b[key] = objFn[key];
		}
		if (!a[key] && a.prop === 'date') {
			a[key] = objFn[key];
		}
	};
	let t = _.cloneDeep(arr);
	t.sort((a, b) => {
		if (sort === 'asc') {
			if (objFn) formatterObjFn(a, b);
			return a[key] - b[key];
		}
		if (sort === 'desc') {
			if (objFn) formatterObjFn(a, b);
			return b[key] - a[key];
		}
	});

	return t;
};
export const switchTime = (time, format) => {
	if (!time) return null;
	const _format = format || '{y}-{m}-{d} {h}:{i}:{s}';
	let date;
	if (typeof time == 'string') {
		if (/^[0-9]+$/.test(time)) {
			time = parseInt(time);
		} else {
			time = time.replace(new RegExp(/-/gm), '/');
		}
	}
	date = new Date(time);
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
	};
	const time_str = _format.replace(/{([ymdhisa])+}/g, (result, key) => {
		const value = formatObj[key];
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value];
		}
		return value.toString().padStart(2, '0');
	});
	return time_str;
};

export const initRoutes = (route = []) => {
	let routes = JSON.parse(JSON.stringify(route));
	let a = (arr = []) => {
		return arr.reduce((cur, next) => {
			if (routers[next.component]) {
				// console.log('next', next)
				// next.meta = 'true'
				if(next.meta) {
					next.meta.keepAlive = true
				}
				next.component = routers[next.component];
				cur.push(next);
				if (next.children?.length) {
					next.children = a(next.children);
				} else {
					if (next.name) {
						let detailEdisable = true
						if (next.name == "sdrl") {
							detailEdisable = false
						} else {
							detailEdisable = true
						}
						const detailEdit = {
							//component: routers['DetailEdit'],
							path: `${next.name}_edit`,
							name: `${next.name}_detailEdit`,
							meta: {
								title: `${next?.meta?.title}详情`,
								keepAlive: detailEdisable,
							},
						};
						const detailAdd = {
							//component: routers['DetailAdd'],
							path: `${next.name}_add`,
							name: `${next.name}_detailAdd`,
							meta: {
								title: `${next?.meta?.title}新增`,
								keepAlive: true,
							},
						};
						const detailView = {
							component: routers['Detail'],
							path: `${next.name}_view`,
							name: `${next.name}_detailView`,
							meta: {
								title: `${next?.meta?.title}查看`,
							},
						};
						//没有该模块可访问的路由处理
						const noAuthDetailEdit = {
							component: routers['Detail'],
							path: `noauth_view`,
							name: `noauth_detailView`,
							meta: {
								title: `单据详情`,
							},
						};
						cur.push(detailEdit, detailAdd, detailView,noAuthDetailEdit);
					}
				}

				return cur;
			}
		}, []);
	};
	routes.forEach(item =>{
	})
	routes = a(routes);
	return routes;
};

export const deepFindOne = (arr = [], key, val) => {
	let box;
	let ac = (arr, key, val) => {
		for (let i = 0, len = arr.length; i < len; i++) {
			if (isArray(val)) {
				let len = val.length;
				while (len--) {
					if (b[key] === val[len]) {
						b.resolvePath = path.resolve(box.path, b.path);
						a.push(b);
					}
				}
			}
			if (isString(val)) {
				if (arr[i][key] === val || (box && path.resolve(box.path, arr[i].path) === val)) {
					arr[i].resolvePath = path.resolve(box.path, arr[i].path);
					return arr[i];
				}
			}
			if (arr[i].children && arr[i].children.length) {
				box = arr[i];
				let v = ac(arr[i].children, key, val);
				if (v) {
					return v;
				}
			}
		}
	};

	return ac(arr, key, val);
};

export const sqlFn = (sql = '', data) => {
	if (!sql || !data) return;
	const fn = match => {
		let _match = match.replace('@', '');
		if (data[_match]) {
			return `'${data[_match]}'`;
		}
		return match;
	};
	const str = sql.replace(/@[a-z0-9][a-z0-9]+/g, fn);

	return str;
};

export const deppZip = (arr = [], key = '') => {
	arr.reduce((a, b) => {
		(a[b[key]] = a[b[key]] || []).push(b);
		return a;
	}, []);
};
export const deepFindOneObj = function(obj = {}, key = '', value = '') {
	if (obj[key] === value) {
		return obj;
	}
	if (obj.children) {
		for (let index = 0; index < obj.children.length; index++) {
			const t = deepFindOneObj(obj.children[index], key, value);
			if (t) return t;
		}
	}
};
  /**
   * 分组小计
   * @param {*} tableData  表格数据
   * @param {*} subtotalGroupFiled  分组字段
   * @param {*} subtotalFiled  小计字段
   * @param {*} fieldsConfigVOs  字段列表
   * @returns 
   */
  export const subtotal = function(tableData,subtotalGroupFiled,subtotalFiled,fieldsConfigVOs){
	if (!tableData){
		return
	}
	const groupBy = (array, key) => {
			return array.reduce((result, currentValue) => {
			  (result[currentValue[key]] = result[currentValue[key]] || []).push(
				currentValue
			  );
			  return result;
			}, {}); 
		  };

		  const sortArray = (prop)=>{
			return function(a, b) {    
				if (a[prop] > b[prop]) {    
					return 1;    
				} else if (a[prop] < b[prop]) {    
					return -1;    
				}    
				return 0;    
			}    
		  }
		  for(let j = tableData.length-1;j >= 0;j--){
			if (tableData[j].subtotal){
				tableData.splice(j,1)
			 }
			
		  }
		  tableData.sort(sortArray(subtotalGroupFiled));
		  let listGroupedBy = groupBy(tableData, subtotalGroupFiled);
		  let index = 0
		  for(let key in listGroupedBy){
			 index = index + listGroupedBy[key].length
			 if (listGroupedBy[key].length > 1){
			   let fields = subtotalFiled
			   let obj = {[subtotalGroupFiled]:'分组小计',subtotal:true}
			   let a = tableData.filter((item)=>{ return item[subtotalGroupFiled] == key})
			   for(let f = 0 ; f < fields.length;f++){
				  let total = 0
				  a.forEach(function(val, idx, arr) {
					  total += parseFloat(val[fields[f]] || 0);
				  }, 0);
				  let filterA = fieldsConfigVOs.filter((a)=>{
					  return a.fieldName.toLowerCase() == fields[f].toLowerCase()
				  })
				  if (filterA && filterA.length > 0){
					obj[fields[f]] = total.toFixed(filterA[0].expressionDecimalDigit || 2)
				  }else{
					obj[fields[f]] = total.toFixed(2)
				  }
			   }
			   tableData.splice(index, 0, obj);
			   index++
			 }
			
			
		  }
  }

/*函数防抖处理*/
export const debounce = function(fn, interval) {
	var timer;
	var gapTime = interval || 1000;//间隔时间
	return function() {
	  clearTimeout(timer);
	  var context = this;
	  var args = arguments;//保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
	  timer = setTimeout(function() {
		fn.call(context,args);
	  }, gapTime);
	};
}

/*浏览器判断*/
export const AgentType = {
	Opera: 'Opera',
	FireFox: 'FireFox',
	Chrome: 'Chrome',
	Safari: 'Safari',
	IE: 'IE',
}

export const getUserAgent = () => {
	const { userAgent } = navigator; // 取得浏览器的userAgent字符串
	// console.log(userAgent)
	if (userAgent.indexOf('Opera') > -1) {
	  // 判断是否Opera浏览器
	  return AgentType.Opera;
	}
	if (userAgent.indexOf('Firefox') > -1) {
	  // 判断是否Firefox浏览器
	  return AgentType.FireFox;
	}
	if (userAgent.indexOf('Chrome') > -1) {
	  // 判断是否为谷歌浏览器
	  return AgentType.Chrome;
	}
	if (userAgent.indexOf('Safari') > -1) {
	  // 判断是否Safari浏览器
	  return AgentType.Safari;
	}
	if ((userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1) || (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1)) {
	  // 判断是否IE浏览器
	  return AgentType.IE;
	}
	return '';
};

