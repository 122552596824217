var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDeepCom(_vm.item)
    ? _c(
        "div",
        [
          _c("Y-tab", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.item.showTabFlag,
                expression: "item.showTabFlag"
              }
            ],
            key: _vm.item.id,
            ref: _vm.item.id,
            attrs: {
              tab: _vm.item.children,
              tabName: "id",
              tabLabel: "header",
              changeActive: _vm.item.childrenActive,
              checkout: _vm.checkout
            },
            on: {
              "update:changeActive": function($event) {
                return _vm.$set(_vm.item, "childrenActive", $event)
              },
              "update:change-active": function($event) {
                return _vm.$set(_vm.item, "childrenActive", $event)
              },
              handleTabClick: _vm.handleTabClick,
              "update:checkout": function($event) {
                _vm.checkout = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var tabItem = ref.tabItem
                    var ref_tabItem = ref.tabItem
                    var fieldsConfigVOs = ref_tabItem.fieldsConfigVOs
                    var tableData = ref_tabItem.tableData
                    var itemsConfigVOs = ref_tabItem.itemsConfigVOs
                    return [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames"
                          }
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { title: "", name: "1" } },
                            [
                              _c(
                                "div",
                                { staticClass: "buttonbox" },
                                [
                                  (_vm.handleType !== "list" &&
                                    !_vm.isView &&
                                    !tabItem.isReadOnly &&
                                    tabItem.header != "占用明细" &&
                                    !tabItem.isCancelFilter &&
                                    tabItem.header != "库存明细") ||
                                  _vm.$route.path == "/cggl/zjjh_edit"
                                    ? _c("Y-button", {
                                        attrs: { button: _vm.button },
                                        on: {
                                          handleClickButton: function(val) {
                                            return _vm.handleClickHandle({
                                              title: "add",
                                              tabItem: tabItem
                                            })
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.handleType !== "list" &&
                                  !_vm.isView &&
                                  !tabItem.isReadOnly &&
                                  tabItem.header != "占用明细" &&
                                  !tabItem.isCancelFilter &&
                                  tabItem.header != "库存明细"
                                    ? _c("Y-button", {
                                        attrs: { button: _vm.buttonse },
                                        on: {
                                          handleClickButton: function(val) {
                                            return _vm.handleClickHandle({
                                              title: "excelCopy",
                                              tabItem: tabItem
                                            })
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.handleType !== "list" &&
                                  !_vm.isView &&
                                  !tabItem.isReadOnly &&
                                  tabItem.header != "占用明细" &&
                                  tabItem.header != "库存明细"
                                    ? _c("Y-button", {
                                        attrs: { button: _vm.buttonedsa },
                                        on: {
                                          handleClickButton: function(val) {
                                            return _vm.handleClickHandle({
                                              title: "deleteall",
                                              tabItem: tabItem
                                            })
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  !_vm.isView &&
                                  tabItem.header == "库存明细" &&
                                  _vm.$route.path.indexOf("add") == -1
                                    ? _c("Y-button", {
                                        attrs: {
                                          button: [
                                            { key: "zhanyong", label: "占用" }
                                          ]
                                        },
                                        on: {
                                          handleClickButton: function(val) {
                                            return _vm.handleClickHandle({
                                              title: "zhanyong",
                                              tabItem: tabItem
                                            })
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  !_vm.isView &&
                                  tabItem.header == "占用主表" &&
                                  _vm.$route.path.indexOf("add") == -1
                                    ? _c("Y-button", {
                                        attrs: {
                                          button: [
                                            {
                                              key: "cancelZhanYong",
                                              label: "取消占用"
                                            }
                                          ]
                                        },
                                        on: {
                                          handleClickButton: function(val) {
                                            return _vm.handleClickHandle({
                                              title: "cancelZhanYong",
                                              tabItem: tabItem
                                            })
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  tabItem.isCancelFilter
                                    ? _c("Y-button", {
                                        attrs: {
                                          button: [
                                            {
                                              key: "cancelFilter",
                                              label: _vm.$t(
                                                "configMoudle.cancelFilter"
                                              )
                                            }
                                          ]
                                        },
                                        on: {
                                          handleClickButton: function(val) {
                                            return _vm.handleClickHandle({
                                              title: "cancelFilter",
                                              tabItem: tabItem
                                            })
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  tabItem.isShowSubtotal
                                    ? [
                                        _c("Y-button", {
                                          attrs: {
                                            button: [
                                              {
                                                key: "subTotalSet",
                                                label: _vm.$t(
                                                  "configMoudle.subTotalSet"
                                                )
                                              }
                                            ]
                                          },
                                          on: {
                                            handleClickButton: function(
                                              $event
                                            ) {
                                              return _vm.showSubTotalDialog(
                                                fieldsConfigVOs,
                                                tabItem.id
                                              )
                                            }
                                          }
                                        }),
                                        _c("Y-button", {
                                          attrs: {
                                            button: [
                                              {
                                                key: "openSubtotal",
                                                label:
                                                  _vm.subtotalSwitchStatus ==
                                                  false
                                                    ? "开启小计"
                                                    : "关闭小计"
                                              }
                                            ]
                                          },
                                          on: {
                                            handleClickButton: function(
                                              key,
                                              value
                                            ) {
                                              _vm.handleSwitchSubTotal(
                                                value,
                                                tableData,
                                                tabItem.id,
                                                fieldsConfigVOs
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "Y-table",
                                _vm._b(
                                  {
                                    key: tabItem.id,
                                    ref: tabItem.id,
                                    attrs: {
                                      "row-class-name": function(ref) {
                                        var row = ref.row
                                        var rowIndex = ref.rowIndex

                                        return (row.$index = rowIndex)
                                      },
                                      isColumnFilter: true,
                                      alingitem: "alignType",
                                      column: fieldsConfigVOs,
                                      itemsConfigVOs: itemsConfigVOs,
                                      isExcelImport: tabItem.isExcelImport,
                                      isExcelExport: tabItem.isExcelExport,
                                      isBatchModify: tabItem.isBatchModify,
                                      tabItemIsReadOnly:
                                        tabItem.peddingReadOnly,
                                      tableData:
                                        tableData && tabItem.isPaged
                                          ? tableData.slice(
                                              (_vm.detailCurrentPage - 1) *
                                                _vm.detailpageSize,
                                              _vm.detailCurrentPage *
                                                _vm.detailpageSize
                                            )
                                          : tableData,
                                      columnKey: "fieldName",
                                      columnLabel: "showName",
                                      height: _vm.theights,
                                      handleBtn: !tabItem.isReadOnly
                                        ? _vm.handleBtn
                                        : [""],
                                      cwqrisok: _vm.cwqrisok,
                                      editCell:
                                        ((tabItem.isInlineEditable ||
                                          _vm.isInlineEditable) &&
                                          !_vm.isView &&
                                          !tabItem.isReadOnly) ||
                                        _vm.$route.path == "/cggl/zjjh_edit",
                                      showSummary: _vm.showSummary,
                                      selection: false,
                                      headerbe: true,
                                      handleDisable: tabItem.isReadOnly
                                        ? true
                                        : _vm.handleBtn.length
                                        ? false
                                        : true
                                    },
                                    on: {
                                      currentChange: function(row) {
                                        return _vm.currentChange(row, tabItem)
                                      },
                                      rowClick: function(row) {
                                        return _vm.handleRowClick(row, tabItem)
                                      },
                                      cellClick: _vm.cellClick,
                                      selectionChage: _vm.handleSelectDtl,
                                      handleClickHandle: function(obj) {
                                        return _vm.handleClickHandle(
                                          Object.assign({}, obj, {
                                            tabItem: tabItem
                                          })
                                        )
                                      },
                                      handleExport: function($event) {
                                        return _vm.handleExport(tabItem)
                                      },
                                      handleImport: function($event) {
                                        return _vm.handleImport(tabItem)
                                      },
                                      handleHeaderEdit: function(column) {
                                        return _vm.handleHeaderEdit(
                                          column,
                                          tabItem
                                        )
                                      },
                                      handleHeaderSearch: function(column) {
                                        return _vm.handleHeaderSearch(
                                          column,
                                          tabItem
                                        )
                                      },
                                      sortChange: _vm.sortChange
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "myColumn",
                                          fn: function(ref) {
                                            var row = ref.row
                                            var column = ref.column
                                            return [
                                              _vm.editShowRowcom(
                                                column,
                                                "editType",
                                                "dataType"
                                              ) === "text"
                                                ? _c("Y-input", {
                                                    ref:
                                                      "y-input-" +
                                                      column["fieldName"],
                                                    attrs: {
                                                      input:
                                                        row[
                                                          column["fieldName"]
                                                        ],
                                                      fetchId: column.fetchId
                                                    },
                                                    on: {
                                                      "update:input": [
                                                        function($event) {
                                                          return _vm.$set(
                                                            row,
                                                            column["fieldName"],
                                                            $event
                                                          )
                                                        },
                                                        _vm.handleInput
                                                      ],
                                                      "hook:mounted": function(
                                                        $event
                                                      ) {
                                                        return _vm.mountedRes(
                                                          "y-input-" +
                                                            column["fieldName"],
                                                          "input"
                                                        )
                                                      },
                                                      blur: _vm.handleBlur,
                                                      suffixclick: function(
                                                        $event
                                                      ) {
                                                        return _vm.suffixclick(
                                                          itemsConfigVOs,
                                                          column
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.editShowRowcom(
                                                column,
                                                "editType",
                                                "dataType"
                                              ) === "number"
                                                ? _c("Y-numberInput", {
                                                    ref:
                                                      "y-numberInput-" +
                                                      column["fieldName"],
                                                    attrs: {
                                                      input: row[
                                                        column["fieldName"]
                                                      ]
                                                        ? row[
                                                            column["fieldName"]
                                                          ]
                                                        : _vm.defaultNumberInput,
                                                      type: "number"
                                                    },
                                                    on: {
                                                      input: function(val) {
                                                        _vm.handleNumberInput(
                                                          val,
                                                          row,
                                                          column,
                                                          itemsConfigVOs
                                                        )
                                                      },
                                                      "hook:mounted": function(
                                                        $event
                                                      ) {
                                                        return _vm.mountedRes(
                                                          "y-numberInput-" +
                                                            column["fieldName"],
                                                          "numberInput"
                                                        )
                                                      },
                                                      blur: _vm.handleBlur
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.editShowRowcom(
                                                column,
                                                "editType",
                                                "dataType"
                                              ) === "date"
                                                ? _c("Y-date", {
                                                    ref:
                                                      "y-date-" +
                                                      column["fieldName"],
                                                    attrs: {
                                                      value:
                                                        row[
                                                          column["fieldName"]
                                                        ],
                                                      type: "date",
                                                      "value-format":
                                                        "timestamp"
                                                    },
                                                    on: {
                                                      input: function(val) {
                                                        return (row[
                                                          column["fieldName"]
                                                        ] = val)
                                                      },
                                                      blur: _vm.handleBlur,
                                                      "hook:mounted": function(
                                                        $event
                                                      ) {
                                                        return _vm.mountedRes(
                                                          "y-date-" +
                                                            column["fieldName"],
                                                          "datePicker"
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.editShowRowcom(
                                                column,
                                                "editType",
                                                "dataType"
                                              ) === "select"
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      ref:
                                                        "el-select-" +
                                                        column["fieldName"],
                                                      attrs: {
                                                        size: "mini",
                                                        "value-key": column.id
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleChange(
                                                            row,
                                                            $event
                                                          )
                                                        },
                                                        "hook:mounted": function(
                                                          $event
                                                        ) {
                                                          return _vm.mountedRes(
                                                            "el-select-" +
                                                              column[
                                                                "fieldName"
                                                              ],
                                                            "select"
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          row[
                                                            column["fieldName"]
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            column["fieldName"],
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row[column['fieldName']]"
                                                      }
                                                    },
                                                    _vm._l(
                                                      column.dicDataItems,
                                                      function(val) {
                                                        return _c("el-option", {
                                                          key:
                                                            val +
                                                            "-select-option",
                                                          attrs: {
                                                            value: column.optionValue
                                                              ? val
                                                              : val[
                                                                  column.optionValue ||
                                                                    "value"
                                                                ] || val,
                                                            label:
                                                              val[
                                                                column.optionLabel ||
                                                                  "label"
                                                              ] || val
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.editShowRowcom(
                                                column,
                                                "editType",
                                                "dataType"
                                              ) === "inputSelect"
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      ref:
                                                        "el-inputSelect-" +
                                                        column["fieldName"],
                                                      attrs: {
                                                        size: "mini",
                                                        "value-key": column.id,
                                                        filterable: "",
                                                        "allow-create": "",
                                                        "default-first-option":
                                                          ""
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleChange(
                                                            row,
                                                            $event
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          row[
                                                            column["fieldName"]
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            column["fieldName"],
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row[column['fieldName']]"
                                                      }
                                                    },
                                                    _vm._l(
                                                      column.dicDataItems,
                                                      function(val) {
                                                        return _c("el-option", {
                                                          key:
                                                            val +
                                                            "-inputSelect-option",
                                                          attrs: {
                                                            value: column.optionValue
                                                              ? val
                                                              : val[
                                                                  column.optionValue ||
                                                                    "value"
                                                                ] || val,
                                                            label:
                                                              val[
                                                                column.optionLabel ||
                                                                  "label"
                                                              ] || val
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.editShowRowcom(
                                                column,
                                                "editType",
                                                "dataType"
                                              ) === "switch"
                                                ? _c("el-switch", {
                                                    attrs: {
                                                      "active-color": "#13ce66",
                                                      "inactive-color":
                                                        "#ff4949"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.handleChange(
                                                          row,
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        row[
                                                          column["fieldName"]
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          column["fieldName"],
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row[column['fieldName']]"
                                                    }
                                                  })
                                                : _vm._e(),
                                              column.fieldName == "img_ids"
                                                ? _c("img", {
                                                    attrs: {
                                                      width: "100px",
                                                      height: "100px",
                                                      src: row.img_ids[0].url,
                                                      alt: ""
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  "Y-table",
                                  _vm.$attrs,
                                  false
                                )
                              ),
                              tabItem.isPaged
                                ? _c("Y-page", {
                                    attrs: {
                                      isPaged: _vm.isPaged,
                                      total:
                                        tabItem.tableData &&
                                        tabItem.tableData instanceof Array
                                          ? tabItem.tableData.length
                                          : 0,
                                      "current-page": _vm.detailCurrentPage
                                    },
                                    on: {
                                      handleSizeChange: _vm.pageSizeChange,
                                      handleCurrentChange: _vm.pageCurrentChange
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "el-upload",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ],
                                  ref: "uploadfile_controls",
                                  staticClass: "excel_upload",
                                  attrs: {
                                    id: "uploadfile_controls",
                                    action: _vm.baseUrl + "/custom/importExcel",
                                    limit: 1,
                                    data: _vm.uploadFormData,
                                    headers: _vm.headers,
                                    "file-list": _vm.uploadFileList,
                                    "before-upload": _vm.beforeAvatarUpload,
                                    "on-success": _vm.avatarUploadSuccess
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      ref: "uploadButton",
                                      attrs: { size: "small", type: "primary" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("configMoudle.clickUpload")
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      tabItem.isShowSubtotal
                        ? _c(
                            "Y-dialog",
                            {
                              attrs: {
                                dialog: _vm.subTotalSetOkEditDialog,
                                title: _vm.$t("configMoudle.subTotalSet"),
                                width: "60%",
                                top: "30vh",
                                destoryOnClone: false
                              },
                              on: {
                                "update:dialog": function($event) {
                                  _vm.subTotalSetOkEditDialog = $event
                                },
                                handleOk: function($event) {
                                  return _vm.handleSubTotalSetOkDialog(
                                    tableData
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "subTotalSet",
                                  attrs: { inline: true }
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: {
                                        label: _vm.$t(
                                          "configMoudle.groupCondition"
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "" },
                                          model: {
                                            value: _vm.subtotalGroupFiled,
                                            callback: function($$v) {
                                              _vm.subtotalGroupFiled = $$v
                                            },
                                            expression: "subtotalGroupFiled"
                                          }
                                        },
                                        _vm._l(_vm.groupFileds, function(item) {
                                          return _c("el-option", {
                                            key: item.fieldName,
                                            attrs: {
                                              label: item.showName,
                                              value: item.fieldName
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "configMoudle.subtotalField"
                                        )
                                      }
                                    },
                                    [
                                      _c("el-transfer", {
                                        attrs: {
                                          titles: [
                                            _vm.$t("configMoudle.notSelected"),
                                            _vm.$t("configMoudle.selected")
                                          ],
                                          props: {
                                            key: "fieldName",
                                            label: "showName"
                                          },
                                          data: _vm.selectTotalfileds
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var option = ref.option
                                                return _c("span", {}, [
                                                  _vm._v(
                                                    _vm._s(option.showName)
                                                  )
                                                ])
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.subtotalFiled,
                                          callback: function($$v) {
                                            _vm.subtotalFiled = $$v
                                          },
                                          expression: "subtotalFiled"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              1484325211
            )
          }),
          _c(
            "Y-dialog",
            {
              attrs: {
                dialog: _vm.dialog,
                title: _vm.dialogTitle,
                destoryOnClone: false
              },
              on: {
                "update:dialog": function($event) {
                  _vm.dialog = $event
                },
                handleOk: _vm.handleOk
              }
            },
            [
              _c("Y-form", {
                attrs: {
                  form: _vm.toForm(_vm.tabItem.itemsConfigVOs) || [],
                  disabledKey: "isReadOnly",
                  formData: _vm.formData,
                  itemLabel: "itemShowName",
                  itemProp: "itemName",
                  itemTypeKey: "editType",
                  firstTab: _vm.firstTab,
                  isImage: _vm.isImage,
                  imageList: _vm.imageList,
                  dataId: _vm.dataId,
                  disabledReadOnly: _vm.isReadOnlyform,
                  inline: ""
                },
                on: {
                  handleFocus: _vm.handleFo,
                  handleUpload: _vm.handleUpload,
                  handleRemove: _vm.handleRemove
                }
              })
            ],
            1
          ),
          _vm.transObj.fieldsVOs.column.length
            ? _c(
                "Y-dialog",
                {
                  attrs: {
                    dialog: _vm.transDialog,
                    title: _vm.transObj.value,
                    top: "1vh"
                  },
                  on: {
                    "update:dialog": function($event) {
                      _vm.transDialog = $event
                    },
                    handleOk: function($event) {
                      return _vm.handleTrans(
                        _vm.transObj.fieldsVOs.tableData,
                        _vm.transObj.fieldsVOs.column
                      )
                    }
                  }
                },
                [
                  _vm.transObj.fieldsVOs.searchInput &&
                  _vm.transObj.fieldsVOs.searchInput.length
                    ? _c("div", { staticClass: "search-zone" }, [
                        _c(
                          "div",
                          { staticClass: "insBtn" },
                          [
                            _c("Y-inputs", {
                              ref: "Yinput",
                              attrs: {
                                inputs: _vm.transObj.fieldsVOs.searchInput,
                                keyEn: "fieldName",
                                keyCh: "showName",
                                itemTypeKey: "dataType"
                              }
                            }),
                            _c("Y-button", {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                button: [
                                  {
                                    key: "search",
                                    label: _vm.$t("configMoudle.search"),
                                    type: "primary"
                                  }
                                ]
                              },
                              on: { handleClickButton: _vm.handleClickButton }
                            })
                          ],
                          1
                        )
                      ])
                    : _c("div"),
                  _c("Y-radioTable", {
                    attrs: {
                      column: _vm.transObj.fieldsVOs.column,
                      tableData: _vm.transObj.fieldsVOs.tableData,
                      "max-height": _vm.tableHeight
                    },
                    on: { roadeChange: _vm.roadeChange }
                  }),
                  _vm.transObj.isPaged
                    ? _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.currentPage,
                          "page-sizes": [20, 50, 100, 200],
                          "page-size": _vm.pageSize,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.totalData
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.transObj.fieldsVOs.column.length
            ? _c(
                "Y-dialog",
                {
                  attrs: {
                    dialog: _vm.transDialogs,
                    title: _vm.transObj.value,
                    top: "1vh"
                  },
                  on: {
                    "update:dialog": function($event) {
                      _vm.transDialogs = $event
                    },
                    handleOk: function($event) {
                      return _vm.handleTranss(
                        _vm.transObj.fieldsVOs.tableData,
                        _vm.transObj.fieldsVOs.column
                      )
                    }
                  }
                },
                [
                  _c("Y-table", {
                    ref: "dialogTableMains",
                    attrs: {
                      column: _vm.transObj.fieldsVOs.column,
                      tableData: _vm.transObj.fieldsVOs.tableData,
                      columnKey: "fieldName",
                      columnLabel: "showName",
                      handleDisable: true,
                      "max-height": _vm.tableHeight
                    },
                    on: { selectionChage: _vm.handleSelect }
                  }),
                  _vm.transObj.isPaged
                    ? _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.currentPage,
                          "page-sizes": [20, 50, 100, 200],
                          "page-size": _vm.pageSize,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.totalData
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.moveLine
            ? _c(
                "Y-dialog",
                {
                  attrs: {
                    dialog: _vm.moveLine,
                    title: "行号",
                    width: "30%",
                    top: "30vh"
                  },
                  on: {
                    "update:dialog": function($event) {
                      _vm.moveLine = $event
                    },
                    handleOk: _vm.handleLine
                  }
                },
                [
                  _c("Y-form", {
                    attrs: {
                      form: _vm.columns,
                      formData: _vm.lineData,
                      inline: ""
                    },
                    on: { handleSelect: _vm.handleSelect }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "Y-dialog",
            {
              attrs: {
                dialog: _vm.headEditDialog,
                title: _vm.$t("configMoudle.bulkEdit"),
                destoryOnClone: false
              },
              on: {
                "update:dialog": function($event) {
                  _vm.headEditDialog = $event
                },
                handleOk: _vm.handleOkDialog
              }
            },
            [
              _vm.columnField.dataType == "text"
                ? _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: _vm.$t("configMoudle.content")
                    },
                    model: {
                      value: _vm.property,
                      callback: function($$v) {
                        _vm.property = $$v
                      },
                      expression: "property"
                    }
                  })
                : _vm._e(),
              _vm.columnField.dataType == "decimal"
                ? _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: _vm.$t("configMoudle.content")
                    },
                    model: {
                      value: _vm.property,
                      callback: function($$v) {
                        _vm.property = $$v
                      },
                      expression: "property"
                    }
                  })
                : _vm._e(),
              _vm.columnField.dataType == "date"
                ? _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: _vm.$t("configMoudle.selectDate")
                    },
                    model: {
                      value: _vm.property,
                      callback: function($$v) {
                        _vm.property = $$v
                      },
                      expression: "property"
                    }
                  })
                : _vm._e(),
              _vm.columnField.dataType == "select"
                ? _c(
                    "el-select",
                    {
                      attrs: {
                        size: "mini",
                        placeholder: _vm.$t("configMoudle.select")
                      },
                      model: {
                        value: _vm.property,
                        callback: function($$v) {
                          _vm.property = $$v
                        },
                        expression: "property"
                      }
                    },
                    _vm._l(_vm.columnField.dicDataItems, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "excel复制",
                visible: _vm.exceldisabled,
                width: "50%",
                "before-close": _vm.exceldisabledNo
              },
              on: {
                "update:visible": function($event) {
                  _vm.exceldisabled = $event
                }
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: "",
                  placeholder: "excel复制的数据"
                },
                model: {
                  value: _vm.exceltextarea,
                  callback: function($$v) {
                    _vm.exceltextarea = $$v
                  },
                  expression: "exceltextarea"
                }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.exceldisabledNo } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exceldisabledYes }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.item.children
            ? _vm._l(_vm.item.children, function(val, id) {
                return _c("Y-common", {
                  key: id,
                  attrs: {
                    item: val,
                    goods: _vm.goodsRow,
                    allData: _vm.allData,
                    formData: _vm.formData,
                    indexList: _vm.indexList,
                    handleBtn: _vm.handleBtns,
                    theights: "300"
                  },
                  on: {
                    countlistdata: function(name, count) {
                      return _vm.countlistdata(name, count, _vm.rowIndex)
                    },
                    handleImportLAist: function(data) {
                      return _vm.handleImportLAist(data, _vm.newrowdata)
                    },
                    updateQty: _vm.updateDetail
                  }
                })
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }