import { switchTime, deepFindOneObj } from "@/utils/methods";

export default {
  computed: {
    _formType() {
      return {
        1: "text",
        3: "date",
        4: "select",
        5: "switch",
        6: "textarea",
        7: "number",
        9: 'multipleSelect',
        10: 'person',
        11: 'citySelect',
        12: 'inputSelect'
      };
    },
    _switchTimeType() {
      return {
        1: "{y}",
        2: "{y}-{m}",
        3: "{y}-{m}-{d}",
        4: "{y}-{m}-{d} {h}",
        5: "{y}-{m}-{d} {h}:{i}",
        6: "{y}-{m}-{d} {h}:{i}:{s}",
      };
    },
  },
  methods: {
    formatData(arrData = [], root) {
      return arrData.map((item) => {
        if (item.hasOwnProperty("update_time")) {
          item.update_time = switchTime(item.update_time);
        }
        if (item.hasOwnProperty("create_time")) {
          item.create_time = switchTime(item.create_time);
        }
        if (item.hasOwnProperty("fdate")) {
          item.fdate = switchTime(item.fdate);
        }
        if (item.hasOwnProperty("$index")) {
          delete item.$index;
        }
        if (root) {
          const v = deepFindOneObj(this.item, "id", root.formId);
          if (v.fieldsConfigVOs?.length) {
            for (let i = 0, len = v.fieldsConfigVOs.length; i < len; i++) {
              if (v.fieldsConfigVOs[i].dataType == 3 || v.fieldsConfigVOs[i].dataType == "date") {
                item[v.fieldsConfigVOs[i].fieldName] = switchTime(
                  item[v.fieldsConfigVOs[i].fieldName],
                  this._switchTimeType[v.fieldsConfigVOs[i].datePrecision]
                );
              }
            }
          }
        }

        if (item._children) {
          item._children = item._children.map((val) => {
            if (val.hasOwnProperty("delList")) {
              val.data = [...val.delList, ...val.data];
              delete val.delList;
            }
            if (val.hasOwnProperty("$index")) {
              delete val.$index;
            }
            if (val.data) {
              val.data = this.formatData(val.data, val);
            }
            return val;
          });
        }
        return item;
      });
    },
    editShowRowcom(obj, ...rest) {
      if (!rest) return;
      let f;
      rest.forEach(key => {
        const val = obj[key];
        ["text", "date", "select", "number", "switch", "inputSelect"].forEach(item => {
          if(item === val) f = val;
        })
        if(val === "decimal") f = "number";
      });
      return f;
    },
    mountedRes(name, ref) {
      if(ref == 'select') this.$refs[name].focus();
      else if(ref == 'datePicker') {
        this.$refs[name].$children[0].$refs.reference.$refs.input.focus()
      }
      else this.$refs[name].$refs[ref].focus();
      // this.$nextTick(() => {
      //   this.$refs[name].$refs[ref].focus();
      // })
    }
  },
};
