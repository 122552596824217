var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          ref: "Ytable",
          attrs: {
            data: _vm.tableData,
            "max-height": _vm.maxHeight,
            border: "",
            size: "mini",
            "highlight-current-row": true
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "单选",
              width: "60",
              align: "center",
              "header-align": "center",
              fixed: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-radio",
                      {
                        staticClass: "radio",
                        attrs: { label: scope.row.id },
                        on: {
                          change: function($event) {
                            return _vm.roadeChange(scope.row)
                          }
                        },
                        model: {
                          value: _vm.radio,
                          callback: function($$v) {
                            _vm.radio = $$v
                          },
                          expression: "radio"
                        }
                      },
                      [_c("span")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "行号",
              type: "index",
              width: "60",
              align: "center",
              fixed: "left",
              "header-align": "center"
            }
          }),
          _vm._l(_vm.columnList, function(item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                prop: item.fieldName,
                label: item.showName,
                sortable: "",
                "min-width":
                  item.width === "auto"
                    ? ""
                    : item.width || item.colWidth || 120,
                "show-overflow-tooltip": item.fieldName != "img_ids"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.dataType == 2 || item.dataType == 4
                                  ? _vm.parseToThousandth(
                                      scope.row[item.fieldName],
                                      item.decimalDigit
                                    )
                                  : scope.row[item.fieldName]
                              ) +
                              " "
                          )
                        ])
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }