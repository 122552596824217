import { Loading } from "element-ui";
import axios from "axios";
import qs from "qs";

export class myLoading {
  constructor(dom) {
    this.loading = null;
    this.dom = dom;
    this.num = 0;
  }
  start(text) {
    if (this.num === 0) {
      this.loading = Loading.service({
        target: this.dom,
        lock: true,
        text,
        spinner: "el-icon-loading",
        background: "hsla(0,0%,100%,.9)",
      });
    }
    this.num++;
  }
  end() {
    this.num--;
    if (this.num === 0) {
      this.loading.close();
    }
  }
}
export class cancelApi {
  constructor() {
    this.paddingApiQueue = new Map();
  }
  add(config) {
    const url = [
      config.method,
      config.url,
      qs.stringify(config.params),
      qs.stringify(config.data),
    ].join("<>");
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!this.paddingApiQueue.has(url)) {
          this.paddingApiQueue.set(url, cancel);
        }
      });
  }
  remove(config) {
    const url = [
      config.method,
      config.url,
      qs.stringify(config.params),
      qs.stringify(config.data),
    ].join("<>");
    if (this.paddingApiQueue.has(url)) {
      const cancel = this.paddingApiQueue.get(url);
      cancel(url);
      this.paddingApiQueue.delete(url);
    }
  }
  clear() {
    for (const [url, cancel] of this.paddingApiQueue) {
      cancel(url);
    }
    this.paddingApiQueue.clear();
  }
}
