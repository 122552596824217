var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", "max-height": "450px" }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "attachPath", label: "附件路径" }
          }),
          _c("el-table-column", {
            attrs: { prop: "realAttachName", label: "附件名称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "attachType", label: "附件类型" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "200", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", {
                      staticClass: "el-icon-download",
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(scope.$index, scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }