var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tags-bar" }, [
    _c(
      "div",
      {
        staticClass: "btns_nav btn-left",
        attrs: { slot: "left-btn" },
        on: { click: _vm.scrollPrev },
        slot: "left-btn"
      },
      [_c("i", { staticClass: "el-icon-arrow-left" })]
    ),
    _c("div", { ref: "scrollTag", staticClass: "scrollTag" }, [
      _c(
        "div",
        { ref: "tags", staticClass: "tags", style: _vm.navStyle },
        _vm._l(_vm.tags, function(tag, index) {
          return _c(
            "router-link",
            {
              key: tag.path + index,
              staticClass: "tags-item",
              class: tag.path == _vm.$route.path ? "active" : "",
              attrs: {
                to: { path: tag.path, query: tag.query, fullPath: tag.fullPath }
              }
            },
            [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.handleTag(tag)
                    }
                  }
                },
                [_vm._v(_vm._s(tag.title))]
              ),
              tag.path !== _vm.noClose
                ? _c("span", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.tagsDel(tag)
                      }
                    }
                  })
                : _vm._e()
            ]
          )
        }),
        1
      )
    ]),
    _c(
      "div",
      {
        staticClass: "btns_nav btn-right",
        attrs: { slot: "rigth-btn" },
        on: { click: _vm.scrollNext },
        slot: "rigth-btn"
      },
      [_c("i", { staticClass: "el-icon-arrow-right" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }