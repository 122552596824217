var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editor" }, [
    _c("div", {
      ref: _vm.id,
      attrs: { id: _vm.id },
      on: { input: _vm.input },
      model: {
        value: _vm.editorData,
        callback: function($$v) {
          _vm.editorData = $$v
        },
        expression: "editorData"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }