import request from '@/axios/emailnet';

// 邮箱登录
export function eLogin(data) {
    return request({
        url: 'user/login',
        method: 'post',
        data
    })
}

// 邮箱注册
export function eRegister(data) {
    return request({
        url: 'user/register',
        method: 'post',
        data
    })
}

// 分页查询联系人
export function elinkman(data) {
    return request({
        url: 'linkman/selectPage',
        method: 'post',
        data
    })
}

// 加载签名
export function eSignName(data) {
    return request({
        url: 'hmailSign/getDefaultByAddr',
        method: 'get',
        data
    })
}

// 更新签名
export function updateSign(data) {
    return request({
        url: 'hmailSign/updateSign',
        method: 'put',
        data
    })
}
// 增加签名
export function addSign(data) {
    return request({
        url: 'hmailSign',
        method: 'post',
        data
    })
}


// 条件查询邮箱配置不分页
export function eMailSettings(data) {
    return request({
        url: 'hmailMailSettings/find_list_mail_settings.json',
        method: 'get',
        data
    })
}

// 获取默认收件人的邮箱配置详细信息
export function eSenderGet() {
    return request({
        url: 'hmailMailSettings/getIsDefault',
        method: 'get'
    })
}

// 发送邮件
export function eSendMail(data) {
    return request({
        url: 'sendMail',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data'},
        data
    })
}

// 保存草稿
export function eSaveMail(data) {
    return request({
        url: 'sendMail/saveDrafEmail',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data'},
        data
    })
}

// 分页查询邮箱信息
export function eGetInboxEmail(data) {
    return request({
        url: 'hmailBase/selectPage',
        method: 'post',
        data
    })
}

// 获取邮件数量
export function eGetInboxEmailNums(){
    return request({
        url: 'hmailBase/searchBoxCount',
        method: 'get'
    })
}

// 获取邮件详情
export function eGetEmailDetails(data){
    return request({
        url: `hmailBase/detail?id=${data}` + '&source=fyx',
        method: 'get'
    })
}

// 获取ip详细信息
export function eIpQuery(data){
    return request({
        url: `baiduMap/ipQuery?ip=${data}` + '&source=fyx',
        method: 'get'
    })
}

// 接收邮件
export function eReceiveMail(id, all, time){
    return request({
        url: `receiveMail?accountId=${id}&receiveAll=${all}&receiveTime=${time}` + '&source=fyx',
        method: 'get'
    })
}

// 获取收件状态
export function eReceiveStatus(id){
    return request({
        url: `receiveMail/getReceiveStatus?userId=${id}` + '&source=fyx',
        method: 'get'
    })
}

// 邮件删除到垃圾箱
export function eMailToTrash(data){
    return request({
        url: 'receiveMail/deleteFlagEmail',
        method: 'post',
        data
    })
}

// 彻底批量删除邮箱信息
export function eMailDelete(data){
    return request({
        url: 'hmailBase/completeDelete',
        method: 'delete',
        data
    })
}

// 通讯录树形列表加载
export function eMailTree(data){
    return request({
        url: 'category/tree',
        method: 'post',
        data
    })
}

// 批量删除邮箱联系人
export function eLinkDel(data){
    return request({
        url: 'linkman/batchDelete',
        method: 'delete',
        data
    })
}

// 获取邮箱联系人详情
export function eLinkDetail(data){
    return request({
        url: `linkman/detail?id=${data}` + '&source=fyx',
        method: 'get',
        data
    })
}

// 通讯录分类详情
export function eCategoryDetail(data){
    return request({
        url: `category/detail?id=${data}` + '&source=fyx',
        method: 'get',
        data
    })
}

// 批量删除通讯录分类
export function eCategoryDelete(data){
    return request({
        url: `category/batchDelete`,
        method: 'delete',
        data
    })
}

// 新增通讯录分类
export function eCategoryInsert(data){
    return request({
        url: 'category/insertInfo',
        method: 'post',
        data
    })
}

// 修改通讯录分类
export function eCategoryEdit(data){
    return request({
        url: 'category/updateInfo',
        method: 'put',
        data
    })
}

// 新增邮箱联系人
export function eLinkInsert(data){
    return request({
        url: 'linkman/insertInfo',
        method: 'post',
        data
    })
}

// 修改邮箱联系人
export function eLinkEdit(data){
    return request({
        url: 'linkman/updateInfo',
        method: 'put',
        data
    })
}

// 邮件删除从垃圾箱还原
export function eMailRestore(data){
    return request({
        url: 'receiveMail/restoreDeleteFlagEmail',
        method: 'post',
        data
    })
}

// 查询邮箱标签目录
export function eMailTagTree(){
    return request({
        url: 'hmailTagCategory/tree_page',
        method: 'get'
    })
}

// 新增邮箱标签目录
export function eMailTagInsert(data){
    return request({
        url: 'hmailTagCategory/tree_insert',
        method: 'post',
        data
    })
}
// 查询当前用户下所有目录列表
export function userTagCategoryListAll(){
    return request({
        url: 'hmailTagCategory/userTagCategoryListAll',
        method: 'get',
    })
}

// 条件查询邮箱配置
export function eMailConfigList(data){
    return request({
        url: 'hmailMailSettings/pagination_mail_settings.json',
        method: 'get',
        data
    })
}

// 邮箱配置详情
export function eMailConfigDetail(id){
    return request({
        url: '/hmailMailSettings/'+id + '',
        method: 'get'
    })
}

// 邮箱配置保存
export function eMailConfigSave(data){
    return request({
        url: '/hmailMailSettings/insert.json',
        method: 'post',
        data
    })
}

// 邮箱配置修改
export function eMailConfigUpdate(id,data){
    return request({
        url: '/hmailMailSettings/'+id + '',
        method: 'put',
        data
    })
}

// 邮箱配置删除
export function eMailConfigDelete(id){
    return request({
        url: '/hmailMailSettings/'+id + '',
        method: 'delete'
    })
}

// 修改邮箱标签目录
export function eMailTagEdit(id, data){
    return request({
        url: `hmailTagCategory/tree_update?id=${id}` + '&source=fyx',
        method: 'put',
        data
    })
}

// 删除邮箱标签目录
export function eMailTagDel(id){
    return request({
        url: `/hmailTagCategory/tree_delete?id=${id}` + '&source=fyx',
        method: 'delete'
    })
}

// 更新邮箱已读状态
export function eMailReadStatus(data){
    return request({
        url: 'hmailBase/update',
        method: 'PUT',
        data
    })
}

// 批量上传
export function eMailuploadReports(data){
    return request({
        url: 'mail_upload/uploadReports',
        method: 'POST',
        data
    })
}

// 邮件模版添加
export function hmailTemplateAdd(data){
    return request({
        url: 'hmailTemplate',
        method: 'POST',
        data
    })
}
// 邮件模版编辑
export function hmailTemplateEdit(data){
    return request({
        url: 'hmailTemplate/updateTemplate',
        method: 'PUT',
        data
    })
}
// 邮件模版删除
export function hmailTemplateDel(id){
    return request({
        url: 'hmailTemplate/deleteById',
        method: 'DELETE',
        params:{ id}
    })
}
// 邮件模版列表
export function hmailTemplateList(){
    return request({
        url: 'hmailTemplate/findList',
        method: 'POST',
        data:{}
    })
}


// 标记邮箱全部已读
export function markAllAsRead(){
    return request({
        url: 'hmailBase/markAllAsRead',
        method: 'post',
    })
}
// 标记邮箱全部已读
export function markSelectedAsRead(data){
    return request({
        url: 'hmailBase/markSelectedAsRead',
        method: 'post',
        data
    })
}

// 移动至文件夹
export function moveToFolder(params,data){
    return request({
        url: 'hmailBase/moveToFolder',
        method: 'post',
        params,
        data
    })
}
