<template>
  <el-table-column
    :width="$route.path == '/cggl/zjjh_edit' ? 200 : handleDisable ? headerWidth : this.$parent.$parent.handleWidth"
    align="center"
    class-name="y-handle"
    fixed="right"
  >
    <template slot="header" slot-scope="scope">
      {{handle}}
      <br v-if="headerbr" />
      <i
        style="margin-left: 10px; cursor: pointer"
        class="el-icon-upload2"
        @click.stop="$emit('handleImport', { e: $event, scope })"
        v-if="isExcelImport"
      ></i>
      <i
        style="margin-left: 10px; cursor: pointer"
        class="el-icon-download"
        @click.stop="$emit('handleExport', { e: $event, scope })"
        v-if="isExcelExport"
      ></i>
    </template>
    <template #default="scope">
      <div
        class="table-handle"
        @click.stop="$emit('handleClickHandle', { e: $event, scope })"
        v-show="!scope.row.subtotal"
      >
      <template v-for="(item, index) in handleBT">
        <!--审批历史按钮需要显示小红点，其他不显示-->
        <el-badge class="dot-item"  :key="'badge'+index + item.key" :is-dot="item.key == 'approvalHistory' && scope.row.newHistoryRecord ">
         <i  :class="item.class"
            :title="item.title"
            :val="item.key"
            style="margin-right: 10px">
          </i>
        </el-badge>
        
      </template>
      </div>
    </template>
  </el-table-column>
</template>
<script>
import { getSession } from "@/utils/methods";
export default {
  name: "Y-table-Handle",
  props: {
    isExcelExport: {
      type: Boolean,
      default: false,
    },
    headerbr: {
      type: Boolean,
      default: false
    },
    isExcelImport: {
      type: Boolean,
      default: false,
    },
    handleDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      handle: '操作',
      headerWidth: '100'
    }
  },
  created() {
    if(getSession('LanguageName') == 'en') {
      this.handle = 'operation'
    }
  },
  computed: {
    handleBT() {
      let vnode = [];
      if (this.$route.path == '/CW/sdrl_edit') {
        let editcount = 0
        for (let i = 0; i < this.$parent.$parent.handleBtn.length; i ++) {
          if (this.$parent.$parent.handleBtn[i].key == "edit") {
            editcount ++
          }
        }
        if (editcount === 0) {
          vnode.push({
            class: "el-icon-edit",
            key: "edit",
            title: "编辑",
          },);
        }
        let deletecount = 0
        for (let i = 0; i < this.$parent.$parent.handleBtn.length; i ++) {
          if (this.$parent.$parent.handleBtn[i].key == "delete") {
            deletecount ++
          }
        }
        if (deletecount === 0) {
          vnode.push({
            class: "el-icon-delete",
            key: "delete",
            title: "删除",
          },);
        }
      }
      if (this.handleDisable) {
        let viewcount = 0
        for (let i = 0; i < this.$parent.$parent.handleBtn.length; i ++) {
          if (this.$parent.$parent.handleBtn[i].key == "view") {
            viewcount ++
          }
        }
        if (viewcount === 0) {
          vnode.push({
            class: "el-icon-view",
            key: "view",
            title: this.$t('configMoudle.view'),
          });
        }
      }
      return vnode.concat(this.$parent.$parent.handleBtn);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
  .dot-item{
    width: 16px;
    height: 16px;
  }
</style>
