var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Examination" },
    [
      _c("div", { staticClass: "leftBox" }, [
        _c(
          "div",
          { staticClass: "topBox" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.disable,
                    expression: "disable"
                  }
                ],
                staticStyle: { "margin-bottom": "10px" },
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.save }
              },
              [_vm._v("审批保存")]
            ),
            _c("br"),
            _c(
              "el-row",
              {
                staticClass: "row-bg",
                attrs: { type: "flex", justify: "start" }
              },
              [
                _c(
                  "div",
                  { staticClass: "topsunbox" },
                  [
                    _c("span", [_vm._v("审批节点: ")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          size: "mini",
                          placeholder: "请选择审批节点"
                        },
                        on: { change: _vm.changeNode },
                        model: {
                          value: _vm.apprValue,
                          callback: function($$v) {
                            _vm.apprValue = $$v
                          },
                          expression: "apprValue"
                        }
                      },
                      _vm._l(_vm.apprValueOptions, function(item, idnex) {
                        return _c("el-option", {
                          key: idnex,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "topsunbox" },
                  [
                    _c("span", [_vm._v("指定审批人: ")]),
                    _c(
                      "el-select",
                      {
                        attrs: {
                          disabled: _vm.selectdisabled,
                          filterable: "",
                          multiple: "",
                          size: "mini",
                          placeholder: "请选择审批人"
                        },
                        on: { change: _vm.changeApprover },
                        model: {
                          value: _vm.approverValue,
                          callback: function($$v) {
                            _vm.approverValue = $$v
                          },
                          expression: "approverValue"
                        }
                      },
                      _vm._l(_vm.approverValueOptions, function(item, idnex) {
                        return _c("el-option", {
                          key: idnex,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "topsunbox" },
                  [
                    _c("span", [_vm._v("是否会审: ")]),
                    _c("el-checkbox", {
                      attrs: { disabled: _vm.selectdisabled },
                      on: { change: _vm.isExaminationcahnge },
                      model: {
                        value: _vm.isExamination,
                        callback: function($$v) {
                          _vm.isExamination = $$v
                        },
                        expression: "isExamination"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottomBox" },
          [
            _c(
              "el-image",
              { attrs: { src: _vm.imgid }, on: { dblclick: _vm.imgdeclick } },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error"
                  },
                  [_c("i", { staticClass: "el-icon-picture-outline" })]
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "rightBox" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "actionBox" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  height: "100%",
                  "max-height": "100%",
                  border: ""
                }
              },
              _vm._l(_vm.historyColumn, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    align: "center",
                    "header-align": "center",
                    prop: item.property,
                    label: item.label,
                    width: item.width,
                    "min-width": item.minwidth ? item.minwidth : ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            item.property == "durationTime"
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          scope.row[item.property] / 3600000
                                        ).toFixed(2)
                                      ) +
                                      "小时 "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(scope.row[item.property]))
                                ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              }),
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "大图",
            visible: _vm.dialogVisible,
            width: "80%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            staticClass: "dialogImg",
            attrs: { src: _vm.imgid, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h1span" }, [
      _c("h1", [_vm._v(" 审 批 历 史 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }