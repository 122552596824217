import { switchTime } from "@/utils/methods";
import { isArray, isNum, isFunction } from "@/utils/validate";
export { switchTime } from "@/utils/methods";

/* 
  '0.0'
  '.0##'
  '0.00'
*/
export function booleanFilter(val, column, itemsConfigVOs) {
  if (val) {
    if (column.datePrecision) {
      switch (column.datePrecision) {
        case 1:
          return switchTime(val, "{y}");
        case 2:
          return switchTime(val, "{y}-{m}");
        case 3:
          return switchTime(val, "{y}-{m}-{d}");
        case 4:
          return switchTime(val, "{y}-{m}-{d} {h}");
        case 5:
          return switchTime(val, "{y}-{m}-{d} {h}:{i}");
        case 6:
          return switchTime(val, "{y}-{m}-{d} {h}:{i}:{s}");
        default:
          return val;
      }
    }
  }
  if (typeof val === "boolean") {
    return val ? "是" : "";
  }
  if (column.dataType === "switch" ||  column.editType === "switch") {
    if (val == true || val == '1') return "是";
    else return "否";
  }
  if (column.dataType === "decimal" || column.dataType === "number" || column.dataType == 2 || column.dataType == 4) {
    // 小数字段显示过来
    let myMap = new Map();
    if(itemsConfigVOs?.length) {
      itemsConfigVOs[0].items.forEach((item) => {
        myMap.set(item.itemName, item)
      })
      column.expressionDecimalDigit = myMap.get(column.fieldName)?.expressionDecimalDigit
    }
    // 显示的千分位
    let Digit = 0
    if(column.decimalDigit) Digit = column.decimalDigit;
    else Digit = 0;
    function toThousands(num, expressionDecimalDigit) {
      let newnum = ""
      if (num === "" || num === undefined || num === null) newnum = "";
      else newnum = num + "";
      if (expressionDecimalDigit !== 0) {
        let [sInt, sFloat] = (Number.isInteger(newnum) ? `${newnum}` : Number(newnum)?.toFixed(expressionDecimalDigit) + "").split('.');
        sInt = sInt.replace(/\d(?=(\d{3})+$)/g, '$&,');
        return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
      } else {
        let [sInt, sFloat] = (Number.isInteger(newnum) ? `${newnum}` : newnum).split('.');
        sInt = sInt.replace(/\d(?=(\d{3})+$)/g, '$&,');
        return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
      }
    }
    val = toThousands(val, Digit);
    return val;
  }
  if (isArray(column.list)) {
    let v;
    if (isArray(val)) {
      v = val.map((item) => column.list.find((j) => j.roleId === item.roleId)?.roleName).join();
    } else {
      v = column.list.find((item) => item[column['optionValue'] || "value"] === val)?.[column["optionLabel"] || "label"];
    }
    return v;
  }
  
  return val;
}
