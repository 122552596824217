<template lang="html">
  <div class="editor">
    <div id="demo2" v-html="editorData" ref="demo2" @input="input">
    </div>
    <!-- <button type="button" class="btn" @click="getEditorData">获取当前内容</button>
    <textarea name="" id="" cols="170" rows="20" readonly v-model="editorDataedsa"></textarea> -->
  </div>
</template>

<script>
import { getSession } from "@/utils/methods";
import wangEditor from 'wangeditor'
export default {
  name: 'editoritem',
  data() {
    return {
    }
  },
  props: ['editorData'],
  mounted() {
    this.initEditor()
  },
  methods: {
    input (e) {
      this.$emit('input', e.target.innerHTML)
    },
    initEditor () {
       // 创建一个editor实例，并将它挂载到，上面的div上，这样这个div就变成了富文本编辑器
      const editor = new wangEditor(this.$refs.demo2)
      // editor.config.uploadImgServer = this.$baseUrl + '/common/fileUpload'
      // editor.config.uploadFileName = 'file'
      // editor.config.uploadImgParams = {
      //   folder: 'wangEditor',
      // }
      editor.config.uploadImgShowBase64 = true
      editor.config.uploadImgHeaders = {
          Authorization: getSession("vue_admin_template_token") 
      }
      editor.config.height = 650
      editor.create()
       // 将父组件中v-model所绑定的变量传递给value，再将value赋值给编辑器的内容。
      editor.txt.html(this.editorData)  
       // 监听value，因为数据流是向下的传递的，父组件v-model绑定的属性一变化，那么value就会变化，从而获取到最新的值，更新编辑器中的内容。
      this.$watch('editorData', () => {  
        editor.txt.html(this.editorData)
      })
    },
    getEditorData() {
      // 通过代码获取编辑器内容
      let data = this.editor.txt.html()
      this.editorDataedsa = data
    },
    beforeDestroy() {
      // 调用销毁 API 对当前编辑器实例进行销毁
      this.editor.destroy()
      this.editor = null
    }
  }
}
</script>

<style lang="scss">
  .editor {
    width: 100%;
    height: 100%;
    margin: auto;
    position: relative;
  }
</style>