import Vue from "vue";
import Vuex from "vuex";
import global from "./global";

Vue.use(Vuex);

const state = {
  count: 0,
  messageCount: 0
}
const actions = {
  saveArr(context){
    context.commit('saveArr');
  },
  increment(context, counted) {
    context.commit('increment', counted);
  },
  updateMsgCount(context, counted) {
    context.commit('updateMsgCount', counted);
  }
}
const mutations = {
  saveArr(state, arrs) {
    state.idParamsList = arrs;
  },
  increment(state, counted) {
    state.count = counted;
  },
  updateMsgCount(state, counted) {
    state.messageCount = counted;
  }
}

export default new Vuex.Store({
  modules: {
    global,
  },
  state,
  mutations,
  actions
});
