<template>
  <el-dialog
    :title="title"
    :visible="dialog"
    :width="width"
    append-to-body
    :destroy-on-close="destoryOnClone"
    :close-on-click-modal="false"
    v-bind="$attrs"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
    @close="$emit('update:dialog', false)"
  >
    <slot></slot>
    <slot name="footer">
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="$emit('update:dialog', false)"
          >{{$t('FetchSet.cancel')}}</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="$emit('handleOk', $slots.default)"
          :loading="loadingqued"
          >{{$t('FetchSet.confirm')}}</el-button
        >
      </span>
    </slot>
  </el-dialog>
</template>

<script>
export default {
  name: "Y-dialog",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "对话框",
    },
    width: {
      type: String,
      default: "80%",
    },
    destoryOnClone: {
      type: Boolean,
      default: true,
    },
    loadingqued: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__footer {
  position: -webkit-sticky!important;
  position: sticky!important;
  bottom: 0;
  right: 0;
  background: #ffffff;
  width: 100%;
  z-index: 100;
}
/deep/.el-dialog__header{
  position: -webkit-sticky!important;
  position: sticky!important;
  top: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  z-index: 100
}
</style>
 