import request from "@/axios/index";
// 登录
export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data,
  });
}
// 退出登录
export function loginOut(data) {
  return request({
    url: "/logout",
    method: "post",
  });
}

// 注册
export function register(data) {
  return request({
    url: "/register",
    method: "post",
    data,
  });
}

// 验证码
export function vertify() {
  return request({
    url: "/captcha_image",
    method: "get"
  });
}

// 修改密码
export function changePassword(params) {
  return request({
    url: "/updatePassword",
    method: "put",
    params
  })
}

// 获取用户信息
export function getUserInfo(params) {
  return request({
    url: "/info",
    method: "get",
    params
  });
}
