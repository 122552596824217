<template>
    <div class="mail-zone">
        <splitpanes class="default-theme">
            <pane class="mail-list">
                <div class="tab-bar">
                    <span>{{this.$t('Emails.emailNums')}}：{{pageNums}}</span>
                    <span v-if="$route.path == '/Email/emailInbox'">{{this.$t('Emails.emailUnread')}}：{{eunread}}</span>
                </div>
                <el-table
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    v-loading="tableLoading"
                    @selection-change="SelectChange"
                >
                    <el-table-column 
                        type="selection" 
                        width="40"
                    ></el-table-column>
                    <el-table-column label="">
                        <template slot-scope="scope">
                            <div 
                                class="mail-item"
                                @click="$event => getContent(scope.row.id)"
                                :style="scope.row.status === 0 ? 'font-weight:bold': ''"
                            >
                                <div class="mail-item-info">
                                    <span style="font-size: 14px;">{{scope.row.sendName}}</span>
                                    <span style="font-size: 14px;">{{scope.row.sendTime}}</span>
                                    <span style="font-size: 14px;">
                                        <i 
                                            class="el-icon-paperclip" v-if="scope.row.accessoryList && scope.row.accessoryList.length"
                                        ></i>{{scope.row.title}}
                                    </span>
                                </div>
                                <div class="mail-item-date">
                                    <span>
                                        <i
                                            v-if="$route.path == '/Email/emailSpam'" 
                                            class="del el-icon-magic-stick"
                                            @click="restoreEmail(scope.row.id)"
                                        ></i>
                                        <i 
                                            v-if="$route.path !== '/Email/emailMyFolders'"
                                            class="del el-icon-delete" @click="delEmail(scope.row.id)"
                                        ></i>
                                    </span>
                                    <div 
                                        style="display:flex;justify-content:end;margin-top:5px"
                                    >
                                        <span v-if="scope.row.isNew==1" class="circleRed"></span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :total="pageNums"
                        :hide-on-single-page="true"
                        @current-change="pageChange"
                    ></el-pagination>
                </div>
            </pane>
            <pane>
                <div class="mail-details">
                    <div class="txt" v-if="showDetail">
                        <div class="info">
                            <span>{{emailDetail.title}}</span>
                            <!-- 发件人-->
                            <span>{{this.$t('Emails.Sender')}}：{{emailDetail.sendName}}</span>
                            <!-- 发送时间 -->
                            <span>{{this.$t('Emails.date')}}：{{emailDetail.sendTime}}</span>
                            <!-- 收件人 -->
                            <span>{{this.$t('Emails.addressee')}}：{{emailDetail.receiver || emailDetail.receiverName}}</span>
                            <!-- 抄送人 -->
                            <span v-if="emailDetail.copyName">{{this.$t('Emails.copyName')}}：{{emailDetail.copyName}}</span>
                            <template v-if="isSent">
                                <span>{{this.$t('Emails.readCount')}}：{{emailDetail.readCount}}</span>
                                <span>{{this.$t('Emails.readTime')}}：{{emailDetail.readTime}}</span>
                                <span>{{this.$t('Emails.readAddress')}}：<a :href="'https://qifu.baidu.com/?activeKey=SEARCH_IP&trace=apistore_ip_aladdin&activeId=SEARCH_IP_ADDRESS&ip='+ipname" target="_blank">{{ipname}}</a></span>
                            </template>
                            <span 
                                v-if="emailDetail.accessoryList && emailDetail.accessoryList.length"
                            >
                                附件：<a style="color:blue">{{emailDetail.accessoryList.length}}个</a>
                            </span>
                            <el-button 
                                plain 
                                size="mini" 
                                class="replyBtn" 
                                @click.native="e => emailReply(e,1)"
                            >{{this.$t('Emails.reply')}}</el-button>
                            <el-button 
                                plain 
                                size="mini" 
                                class="replyBtn" 
                                @click.native="e => emailReply(e,2)"
                            >{{this.$t('Emails.replyAll')}}</el-button>
                        </div>
                        <div 
                            class="detail"
                            style="max-height: 540px;" 
                        >
                            <EmailContent 
                                v-if="showContent" 
                                :content="emailDetail.content" 
                            />
                        </div>
                        <!-- <div 
                            class="detail"
                            style="max-height: 540px;" 
                            v-if="$route.path == '/Email/emailInbox'"
                        >
                            <EmailContent 
                                v-if="showContent" 
                                :content="emailDetail.content" 
                            />
                        </div>
                        <div 
                            class="detail"
                            style="max-height: 540px;" 
                            v-else="$route.path == '/Email/emailSent'"
                        >
                            <EmailContent 
                                v-if="showContent" 
                                :content="emailDetail.content" 
                            />
                        </div> -->
                        <div 
                            v-if="emailDetail.accessoryList && emailDetail.accessoryList.length" 
                            id="attachment" 
                            a="" 
                            b="false" 
                            style="padding: 2px; height: auto;background: #dde7f9;" 
                            class="attbg" 
                            ui-type="attCon"
                        >
                            <div 
                                style="padding:6px 10px 10px 8px;" class="txt_left"
                            >
                                <div style="height:14px;">
                                    <b style="font-size:14px;">附件</b>
                                    (
                                    <span id="attachmentCount">
                                        {{emailDetail.accessoryList.length}}
                                    </span>
                                    个)
                                </div>
                            </div>
                            <div 
                                style="padding:0 8px 6px 12px;background:#fff;_height:60px;line-height:140%;" 
                                v-for="(item,index) in emailDetail.accessoryList" 
                                :key="index"
                            >
                                <div class="att_bt attachitem">
                                    <div class="ico_big">
                                        <span player="/cgi-bin/download?mailid=ZL0018_aujN~KmM434unNgAJ6ZfYc3&amp;filename=tcpServer.log&amp;sid=UtBPTh_Cd_rZAkYk">
                                            {{item.name}}
                                        </span>
                                        <span class="graytext">	&nbsp;({{item.size}})
                                        </span>
                                        <div class="down_big">
                                            &nbsp;&nbsp;
                                            <a :href="baseURL + '/mail_upload/static?id=' + item.id" target="_blank"
                                            unset="true">下载</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="txt" v-else>
                        <el-empty :description="this.$t('Emails.emailDetails')"></el-empty>
                    </div>
                </div>
            </pane>
        </splitpanes>
    </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

export default {
    name: "mailZone",
    components: { Splitpanes, Pane },
    props: {
        tableData: {
            type: Array,
            default: []
        },
        tableLoading: {
            type: Boolean,
            default: false
        },
        pageNums: {
            type: Number,
            default: 0
        },
        eunread: {
            type: Number,
            default: 0
        },
        // 是否是发件箱页
        isSent: {
            type: Boolean,
            default: false
        },
        // 是否显示邮件内容
        showContent: {
            type: Boolean,
            default: false
        },
        ipname: {
            type: String,
            default: ""
        },
        EmailContent: {
            type: Object,
            default: () => {}
        },
        showDetail: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            baseUrl: '',
            emailDetail: {}
        }
    },
    mounted() {
        this.baseURL = this.$emailBaseUrl;
    },
    watch: {
        'EmailContent': {
            handler(obj) {
                this.emailDetail = obj;
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        // 表格多选
        SelectChange(list) {
            this.$emit("tableSelect", list);
        },
        // 获取邮件详情
        getContent(id) {
            this.$emit("getDetail", id);
        },
        // 恢复邮件
        restoreEmail(id) {
            this.$confirm(this.$t('Emails.restore')).then(_ => this.$emit("restoreEmail", id));
            event.stopPropagation();
        },
        // 删除邮件
        delEmail(id) {
            this.$confirm(this.$t('userAdmin.tip3')).then(_ => this.$emit("delEmail", id));
            event.stopPropagation();
        },
        // 表格分页
        pageChange(page) {
            this.$emit('pageChange', page);
        },
        // 邮件回复
        emailReply(e, action) {
            this.$emit('emailReply', action);
        }
    },
}

</script>

<style scoped lang="scss">
.splitpanes.default-theme .splitpanes__pane{
    background-color: initial;
}
.circleRed {
    display: block;
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 50%;
    margin-right: 7px;
}
.circleBlue {
    display: block;
    width: 8px;
    height: 8px;
    background: blue;
    border-radius: 50%;
    margin-right: 7px;
}
.mail-zone {
    width: 100%;
    display: flex;
    .mail-list {
        padding: 10px;
        height: 100%;
        .tab-bar {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            span {
                font-size: 14px !important;
                color: #848994;
            }
        }
        .mail-item {
            display: flex;
            font-size: 12px !important;
            .mail-item-info,
            .mail-item-date {
                display: flex;
                flex-direction: column;
                span {
                    &:nth-of-type(1) {
                        padding-right: 5px;
                    }
                }
            }
            .mail-item-info {
                flex: 0 0 65%;
                span {
                    &:first-of-type {
                        color: #848994;
                    }
                    &:last-of-type {
                        color: #333;
                    }
                }
            }
            .mail-item-date {
                flex: 1;
                span {
                    text-align: right;
                    &:first-of-type {
                        color: #848994;
                    }
                    .del {
                        text-align: right;
                        padding-right: 5px;
                        font-size: 14px;
                        color: violet;
                        cursor: pointer;
                    }
                }
            }
        }
        .pagination {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }
    .mail-details {
        flex: 1;
        height: 100%;
        padding-left: 20px;
        .txt {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .info {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid lightgray;
                margin-bottom: 10px;
                span {
                    color: #999;
                    margin-bottom: 5px;
                    &:first-of-type {
                        color: #333;
                    }
                }
                .replyBtn {
                    width: 80px;
                    margin-bottom: 10px;
                }
            }
            .detail {
                flex: 1;
                overflow-y: auto;
            }
        }
    }
}
/deep/.el-table th.el-table__cell>.cell {
    padding-left: 14px;
}
/deep/.el-table {
    max-height: 640px;
    overflow-y: auto;
}
/deep/.el-button+.el-button {
    margin-left: 0;
}
::-webkit-scrollbar{
    height: 9px;
    width: 9px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border-style: dashed;
    border-color: transparent;
    border-width: 2px;
    background-color: rgba(157, 165, 183, 0.4);
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(157, 165, 183, 0.7);
}
</style>