var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      staticStyle: { height: "285px" },
      on: { "tab-click": _vm.tabClick },
      model: {
        value: _vm.activeName,
        callback: function($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName"
      }
    },
    _vm._l(_vm.tab, function(item, index) {
      return _c(
        "el-tab-pane",
        {
          key: "tabs-" + item[_vm.tabName],
          attrs: { name: item[_vm.tabName] }
        },
        [
          _c("div", { attrs: { slot: "label" }, slot: "label" }, [
            _vm._v(_vm._s(item[_vm.tabLabel]))
          ]),
          _vm._t("default", null, { tabItem: item, $index: index })
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }