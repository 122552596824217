export default {
  user: {
    quit: 'quit'
  },
  // 列表设置
  listOfSet: {
    add: 'add',
    clearCatch: 'clearCatch',
    loadField: 'load field',
    copyModule: 'copy module',
    search: "search",
    reset: "reset",
    edit: '修改',
    colWidth: 'colWidth',
    moduleName: 'moduleName',
    mainTable: 'mainTable',
    foreignKey: 'foreignKey',
    listSql: 'listSql',
    isImage: 'isImage',
    imageColumn: 'imageColumn',
    storedProcedure: 'storedProcedure',
    isReadOnly: 'isReadOnly',
    isInlineEditable: 'isInlineEditable',
    isUpdateKeyOnImport: 'isUpdateKeyOnImport',
    isCheckFno: 'noCheckFnoUnique',
    parentModule: 'parentModule',
    isPage: 'isPage',
    contactSql: 'contactSql',
    listStoredProcedure: 'listStoredProcedure',
    checkStoredProcedure: 'checkStoredProcedure',
    isShowList: 'isShowList',
    tableImgShow: 'tableImgShow',
    formImgShow: 'formImgShow',
    isImport: 'isImport',
    isExport: 'isExport',
    isSendEmail: 'isSendEmail',
    isUploadFile: 'isUploadFile',
    isBatchModify: 'isBatchModify',
    isAboutTypography: 'isAboutTypography',
    isGlobalRetrieval: 'isGlobalRetrieval',
    tableFieldSet: 'tableFieldSet',
    formFieldSet: 'formFieldSet',
    languageSet: 'languageSet',
    sheetSet: 'sheetSet',
    isDbClkJump: 'isDbClkJump' ,
    isShowSubtotal: 'isShowSubtotal',
    showTheApprovalTab: 'showTheApprovalTab',
    passProcName: 'passProcName',
    rejectProcName: 'rejectProcName',
    pageSize: 'pageSize',
    addSuccess: 'Successfully added',
    editSuccss: 'Successfully updated',

    /* 字段设置 */
    fieldShowOrder: 'fieldShowOrder',
    databaseFieldName: 'databaseFieldName',
    showName: 'showName',
    dataType: 'dataType',
    alignType: "alignType",
    queryOperator: "queryOperator",
    isSelect: 'isSelect',
    isLocked: "isLocked",
    isGlobalQuery: 'isGlobalQuery',
    isSum: 'isSum',
    isShow: 'isShow',
    dataDict: 'dataDict',
    datePrecision: "datePrecision",
    language: 'language',
    databaseFields: 'databaseFields',

    //编码设置
    save: 'save',
    year: 'year(2)',
    years: 'year(4)',
    month: 'month',
    date: 'date',
    fixedContent1: 'fixedContent1',
    fixedContent2: 'fixedContent2',
    serialNumber: 'serialNumber',
    loginId: 'loginId',
    serialNoDigit: 'serialNoDigit',
    serialNoUt: 'serialNoUt',
    pattern: 'pattern',
    formField1: 'formField1',
    formField2: 'formField2',
    formField3: 'formField3',
    formField4: 'formField4',
    formField5: 'formField5',
    smallSerialStart: 'smallSerialStart',
    bigSerial: 'big Serial',
    resetSuccess: 'Reset succeeded'
  },
  FormOfSet: {
    add: 'add',
    fieldOrder: 'fieldOrder',
    name: 'name',
    showName: 'showName',
    defaultValue: 'defaultValue',
    expressionDecimalDigit: 'expressionDecimalDigit',
    dataType: 'dataType',
    editType: 'editType',
    formConfigId: 'formConfigId',
    isMustFill: 'isMustFill',
    approveParam: 'approveParam',
    approveChange:'approveChangeFile',
    combinedField: 'combinedField',
    majorKey: 'majorKey',
    isShow: 'isShow',
    isReadOnly: 'isReadOnly',
    dataDict: 'dataDict',
    remark: 'remark',
    sheet: 'sheet',
    expression: 'expression',
    clauseTemplete: 'clauseTemplete',
    widthType: 'widthType',


  },

  // 配置模块
  configMoudle: {
    transferOrder: 'Transfer Order',
    highSeasCustomers: 'High Seas Customers',
    formalCustomers: 'Formal Customers',
    sendEmail: 'sendEmail',
    excelCopy: 'Excel copy',
    claim: 'claim',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    bulkEdit: 'bulkEdit',
    content: 'content',
    selectDate: 'selectDate',
    select: 'select',
    commodityTypeAdmin: 'commodityTypeAdmin',
    parentType: 'parentType',
    TypeName: 'TypeName',
    clickUpload: 'clickUpload',
    fileView: 'fileView',
    Viewflie: 'Viewflie',
    save: 'save',
    printFormat: 'printFormat',
    cancel: 'cancel',
    print: 'print',
    globalQuery: 'globalQuery',
    modelName: 'modelName',
    search: 'search',
    img: 'img',
    tip1: 'Please enter a category name',
    tip2: 'modify successfully ',
    tip3: 'successfully added',
    tip4: 'Please select one',
    tip5: 'Are you sure you want to delete the current category and subcategories',
    tip: 'Tip',
    confirm: 'confirm',
    cancel: 'cancel',
    tip6: 'successfully delete',
    refresh: 'refresh',
    view: 'view',
    versionView: 'versionView',
    tip7: 'Only Excel format can be uploaded  ',
    tip8: 'You are not the originator of this document',
    tip9: 'The document is under approval or has been approved',
    tip10: 'You did not select a row',
    tip11: 'Do not check approved or pending rows',
    tip12: 'Select a line and then modify it',
    tip13: 'Please select one line before printing',
    tip14: 'Please select report output format!',
    tip15: 'Please select module name!',
    tip16: 'Print success',
    tip17: 'downLoad success',
    approvalHistory:'Approval history',
    purchaseContract: 'Purchase Contract',

    // 详情
    fetch: 'fetch',
    approval: 'approval',
    reject: 'reject',
    approvalOption: 'approvalOption',
    sendEmail: 'sendEmail',
    Examination: 'Examination',
    AnnextopurchaseContract: 'Annex to purchase Contract',
    Appendixtoexportcontract: 'Appendix to export contract',
    uploadFile: 'uploadFile',
    report: 'report',
    includeSqlFinish: 'includeSqlFinish',
    viewLargeImg: 'viewLargeImg',
    save: 'save',
    status: 'status',
    copy: 'copy',
    topcopy: 'top Copy',
    bottomcopy: 'bottom Copy',
    moveRow: 'moveRow',
    update: 'update',
    submit: 'submit',
    recall: 'recall',
    list: 'list',
    copyInvoice: 'copyInvoice',
    tip17: 'Please select the content and click OK',
    tip18: 'The page has been refreshed. Please go to this page again and perform this operation!',
    subTotalSet: 'Subtotal config',
    openSubtotal: 'Open subtotal',
    closeSubtotal: 'Close Subtotal',
    groupCondition: 'Group Condition',
    subtotalField: 'Subtotal Field',
    notSelected:'Not Selected',
    selected:'Selected',
    goLeft:'Left',
    goRight:'Right',
    noFno: 'Cannot find this contract number, unable to jump',

    // Y-common
    linenum: 'linenum',
    batchDelete: 'batchDelete',
    replaceLineNumber: 'replaceLineNumber',
    tip19: 'The line number you entered is invalid',
    tip20: 'In approval state...',
    tip21: 'Please select the data to delete',
    tip22: 'Only one line can be selected for newline',
    cancelFilter: 'cancelFilter',


    SalesContract: 'Sales Contract ',
    PurchaseContract: 'Purchase contract',
    alteration: 'alteration',
    cancelAlteration: 'cancelAlteration'

  },

  // 列表设置-----语言设置
  LanguageSet: {
    add: "add",
    language: 'language',
    content: 'content',
    operation: 'operation',
    simplifiedChinese: "simplifiedChinese",
    confirm: "confirm",
    cancel: "cancel"
  },

  // 列表设置--- sheet页设置
  SheetSetting: {
    name: 'name',
    AccordingToTheOrder: 'AccordingToTheOrder',
    add: 'add'
  },

  // 调取设置
  FetchSet: {
    sequence: 'sequence',
    add: 'add',
    name: 'name',
    targetModuleId: 'targetModuleId',
    parentModuleId: 'parentModuleId',
    targetField: 'targetField',
    isPaged: 'isPaged',
    fetchSql: 'fetchSql',
    isShowImage: 'isShowImage',
    showColumns: 'showColumns',
    isRetrieval: 'isRetrieval',
    containsCalledSQL: 'containsCalledSQL',
    edit: 'edit',
    delete: 'delete',
    languageSetting: 'languageSetting',
    tableFiledSetting: 'tableFiledSetting',
    fetchWarinning: 'Please fill in the retrieval statement',
    cancel: 'cancel',
    confirm: 'confirm'
  },

  // 调取设置 ---- 调取字段设置
  FetchKeySet: {
    add: 'add',
    fieldShowOrder: 'fieldShowOrder',
    databaseFieldName: 'databaseFieldName',
    showName: 'showName',
    dataType: 'dataType',
    alignType: "alignType",
    isSelect: 'isSelect',
    queryOperator: "queryOperator",
    isSortable: 'isSortable',
    isFetched: "isFetched",
    isLocked: "isLocked",
    targetField: "targetField",
    isShow: "isShow",
    datePrecision: "datePrecision",
  },


  // 首页设置
  homeSet: {
    homeSet: "homeSet",
    roleNamePlaceHolder: 'roleNamePlaceHolder',
    warningSet: 'warningSet',
    reportSet: 'reportSet',
    earlyWarning: 'earlyWarning',
    title: 'title',
    router: 'router',
    sql: 'sql',
    languageSetting: 'languageSetting',
    save: 'save',
    select: 'Please select',
    inputPlaceHolder: 'inputPlaceHolder',
    report: 'report',
    save: 'save',
    add: 'add',
    edit: 'edit',
    submitTip: 'The submitted data cannot be empty',
    saveTip: 'Save Success',
    completeTip: 'Please improve the data and save it before setting the language',
    saveFailed: 'Save Failed',
    qrSetting: 'QR code setting',
    qrUpload: 'App QR code upload',
    qrEdit: 'QR code information editing',
    qrPlaceholder: 'Please enter the information to edit',
    qrTips: 'Please upload the picture first'
  },

  // 报表权限设置
  ReportSet: {
    jimuReportPermissionSetting: 'jimuReportPermissionSetting',
    roleNamePlaceHolder: 'Enter a role name',
    save: 'save',
    code: 'code',
    reportName: 'reportName',
    createTime: 'createTime',
    warningTip: 'Please select at least one option',
    saveTip: 'Save Success',
    add: 'add',
    edit: 'edit',
  },

  // 字典设置
  DictSet: {
    searchButton: 'search',
    resetButton: 'reset',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    dictName: 'dictName',
    isForbidden: 'isForbidden',
    remark: 'remark',
    dataItemSetting: 'dataItemSetting',
    name: 'name',
    alias: 'alias',
    sortOrder: 'sortOrder',
    desc: 'desc',
    dataPermission: 'dataPermission',
  },

  // 菜单管理
  menuSet: {
    menuName: 'menuName',
    search: 'search',
    reset: 'reset',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    icon: 'icon',
    type: 'type',
    orderNum: 'orderNum',
    permsCode: 'permsCode',
    remark: 'remark',
    routingPath: 'routingPath',
    menuStatus: 'menuStatus',
    addMenu: 'addMenu',
    menuType: 'menuType',
    menuIcon: 'menuIcon',
    menuIconColor: 'menuIconColor(multicolor)',
    menuVisable: 'menuVisible',
    menuOrder: 'menuOrder',
    parentCatalogue: 'parentCatalogue',
    associatedModule: 'associatedModule',
    buttonName: 'buttonName',
    language: 'language',
    deleteTip: 'Confirm deletion?',
    tip: 'Tip',
    confirm: 'confirm',
    cancel: 'cancel',
    firstSelectDelete: 'Please select before deleting',
    deleteSuccess: 'delete Success',
    action: 'operation'
  },

  // 用户管理
  userAdmin: {
    deptPlace: 'Please enter a department name',
    userName: 'userName',
    password: 'password',
    loginAccount: 'loginAccount',
    phoneNumber: 'phoneNumber',
    status: 'status',
    createTime: 'createTime',
    search: 'search',
    reset: 'reset',
    add: 'add',
    passOn: 'passOn',
    deptName: 'deptName',
    nickName: 'nickName',
    englishName: 'englishName',
    email: 'email',
    roleName: 'roleName',
    sex: 'sex',
    postId: 'postId',
    avatar: 'avatar',
    deptId: 'deptId',
    passoword: 'passoword',
    remark: 'remark',
    dept: 'dept',
    edit: 'edit',
    delete: 'delete',
    tip1: 'Select one to modify',
    loadingtip: 'Loading, please wait...',
    tip2: 'Select an item to delete',
    tip3: 'Confirm deletion?',
    tip: 'Tip',
    confirm: 'confirm',
    cancel: 'cancel',
    deleteSuccess: 'deleteSuccess!',
    editSuccess: 'editSuccess!',
    noEmpty: 'can not be empty',
    limitOfAuthority: 'limit of authority',
    synchronousAssignmentGroup: 'synchronous assignment group',
  },

  // 用户管理 --- 编辑用户
  editUserAdmin: {
    save: 'save',
    sync: 'sync',
    add: 'add',
    userInfo: 'userInfo',
    nickName: 'nickName',
    englishName: 'englishName',
    password: 'password',
    phoneNumber: 'phoneNumber',
    remark: 'remark',
    loginAccount: 'loginAccount',
    userEmail: 'userEmail',
    postId: 'postId',
    dept: 'dept',
    roleName: 'role',
    superiorLeaders: 'superiorLeaders',
    sex: 'sex',
    status: 'status',
    emailConfig: 'emailConfig',
    dataPermission: 'dataPermission',
    modelName: 'modelName',
    groupName: 'groupName',
    groupCode: 'groupCode',
    edit: 'edit',
    delete: 'delete',
    loadingtip: 'Loading, please wait...',
    saveSuccess: 'saveSuccess',
    emailUserName: 'addresserName',
    userEmail: 'addresserEmail',
    emailPassword: 'emailPassword',
    smtpHost: 'SMTPAddress',
    smtpPort: 'SMTPPort',
    signature: 'signature',
    ownPhone: 'Private mobile number',
    qq: 'QQ' ,
    weChat: 'WeChat' ,
    platformName: 'Platform Name' ,
    idcard: 'idcard' ,
    isEmailManage: 'Whether an email administrator'
  },



  // 在线用户

  userOnline: {
    userName: 'userName',
    name: 'name',
    search: 'search',
    reset: 'reset',
    kickOut: 'kickOut',
    email: 'email',
    phone: 'phone',
    sex: 'sex',
    loginConputerIp: 'loginConputerIp',
    loginTime: 'loginTime',
    nowTime: 'nowTime',
    status: 'status',
    remark: 'remark',
    tip1: 'Confirm to kick out the user?',
    tip: 'Tip',
    confirm: 'confirm',
    cancel: 'cancel',
    tip2: 'Please select before kicking out!',
    tip3: 'User kicked out'
  },


  // 角色管理

  roleAdmin: {
    tip1: 'Are you sure you want to delete the selected role?',
    menuAllot: 'menuAllot',
    roleName: 'roleName',
    status: 'status',
    search: 'search',
    reset: 'reset',
    add: 'add',
    save: 'save',
    showSort: 'showSort',
    roleStatus: 'roleStatus',
    permissionChar: 'permissionChar',
    permissionScope: 'permissionScope',
    dataScope: 'dataScope',
    remark: 'remark',
    edit: 'edit',
    delete: 'delete',
    tip2: 'noEmpty!',
    tip3: 'Please select before deleting',
    tip4: 'Please select the menu to modify the assignment',
    tip5: 'saveSuccess'
  },


  // 部门管理
  deptAdmin: {
    deptName: 'deptName',
    status: 'status',
    search: 'search',
    reset: 'reset',
    remark: 'remark',
    add: 'add',
    deptCode: 'deptCode',
    departmentHead: 'departmentHead',
    contactWay: 'contactWay(phone)',
    showSort: 'showSort',
    createTime: 'createTime',
    addDept: 'addDept',
    superiorDepartment: 'superiorDepartment',
    edit: 'edit',
    delete: 'delete',
    tip1: 'Are you sure to delete the checked departments?',
    tip: 'Tip',
    confirm: 'confirm',
    cancel: 'cancel',
    tip2: 'Please select before deleting!',
    tip3: 'deleteSuccess'
  },

  //打印管理
  printAdmin: {
    add: 'add',
    modelNumber: 'moduleNumber',
    modelCode: 'moduleCode',
    modelId: 'moduleId',
    modelName: 'moduleName',
    mainTableName: 'mainTableName',
    reportName: 'reportName',
    idField: 'idField',
    remark: 'remark',
    edit: 'edit',
    delete: 'delete',
    tip1: 'Are you sure to delete the checked modules?',
    tip: 'Tip',
    confirm: 'confirm',
    cancel: 'cancel',
    tip2: 'Please select before deleting',
    tip3: 'deleteSuccess',
  },

  // 系统日志
  Systemlog: {
    tip: 'tip',
    requestParams: 'requestParams',
    returnParams: 'returnParams',
    module: 'module',
    status: 'status',
    message: 'message',
    requestMethodName: 'requestMethodName',
    requestIp: 'requestIp',
    requestWay: 'requestWay',
    bussinessType: 'bussinessType',
    cancel: 'cancel',
    confirm: 'confirm',
    search: 'search',
    reset: 'reset',
    add: 'add',
    delete: 'delete',
    tip1: 'Are you sure to delete the checked parameters?',
    tip2: 'Please select before deleting',
    tip3: 'deleteSuccess'
  },

  // 模板类型
  termsTemplete: {
    add: 'add',
    templeteName: 'templeteName',
    templeteContent: 'templeteContent',
    content: 'content',
    templeteType: 'templeteType',
    createBy: 'createBy',
    createTime: 'createTime',
    updateBy: 'updateBy',
    updateTime: 'updateTime',
    selectDate: 'selectDate',
    cancel: 'cancel',
    confirm: 'confirm',
    search: 'search',
    reset: 'reset',
    delete: 'delete',
  },

  //预警设置
  warning: {
    content: 'content',
    select: 'select',
    warningTitle: 'warningTitle',
    isPage: 'isPage',
    warningSql: 'warningSql',
    CustomMenuName: 'CustomMenuName',
    remark: 'remark',
    showOrder: 'showOrder',
    isShow: 'isShow',
    cancel: 'cancel',
    confirm: 'confirm',
    add: 'add',
    edit: 'edit',
    batchDeletes: 'batchDeletes',
    delete: 'delete',
    languageSet: 'languageSet',
    warningFieldSet: 'warningFieldSet',
    tip1: 'Please select the data to delete',
    tip2: 'addSuccess'
  },
  // 预警设置---预警字段设置
  warnFieldSet: {
    databaseName: 'databaseName',
    search: 'search',
    fieldShowOrder: 'fieldShowOrder',
    datebaseField: 'datebaseField',
    showName: 'showName',
    dataType: 'dataType',
    select: 'select',
    alignType: 'alignType',
    isSelect: 'isSelect',
    QueryOperator: 'QueryOperator',
    isLock: 'isLock',
    datePrecision: 'datePrecision',
    isShow: 'isShow',
    cancel: 'cancel',
    confirm: 'confirm',
    add: 'add',
    edit: 'edit',
    languageSet: 'languageSet',
    tip1: 'Please select the data to delete',
    tip2: 'add Success',
  },


  // 流程模型
  flowModel: {
    name: 'name',
    search: 'search',
    reset: 'reset',
    add: 'add',
    flowKey: 'flowKey',
    version: 'version',
    createTime: 'createTime',
    updateTime: 'updateTime',
    metadata: 'metadata',
    edit: 'edit',
    delete: 'delete',
    upload: 'upload',
    download: 'download',
    downLoad: 'downLoad',
    addFlowModel: 'addFlowModel',
    category: 'category',
    desc: 'desc',
  },

  // 流程定义
  flowDefinition: {
    operation: 'operation',
    moduleId: 'moduleId',
    moduleName: 'moduleName',
    programmeId:'programmeId',
    programmeName:'programmeName',
    processDefinitionKey: 'processDefinitionKey',
    remark: 'remark',
    tip4: 'Are you sure to delete the checked modules?',
    tip5: 'Please select before deleting',
    tip6: 'saveSuccess',
    save: 'save',
    active: 'active',
    hangUp: 'hangUp',
    transferModel: 'transferModel',
    delete: 'delete',
    upload: 'upload',
    category: 'category',
    select: 'select',
    selectFile: 'selectFile',
    clickUpload: 'clickUpload',
    cancel: 'cancel',
    confirm: 'confirm',
    flowChart: 'flowChart',
    flowKey: 'flowKey',
    flowName: 'flowName',
    search: 'search',
    reset: 'reset',
    flowId: 'flowId',
    version: 'version',
    flowDesc: 'flowDesc',
    uploadTime: 'uploadTime',
    flowDefinition: 'flowDefinition',
    deploymentProcessDefinition: 'deploymentProcessDefinition',
    flowDefinitionStatus: 'flowDefinitionStatus',
    edit: 'edit',
    delete: 'delete',
    actived: 'actived',
    tip1: 'Are you sure you want to delete this process?',
    tip: 'Tip',
    confirm: 'confirm',
    cancel: 'cancel',
    tip2: 'delete Success',
    tip3: 'Please select category and submit again',

  },

  // 审批
  Approval: {
    operation: 'operation',
    agree: 'agree',
    reject: 'reject',
    approveParam: 'approveParam',
    history: 'history',
    schedule: 'schedule',
    view: 'view',
    confirm: 'confirm',
    cancel: 'cancel',
    approvalOption: 'approvalOption',
    approvalHistory: 'approvalHistory',
    scheduleChart: 'scheduleChart',
    initiator: 'initiator',
    moduleName: 'moduleName',
    search: 'search',
    reset: 'reset',
    refresh: 'refresh',
    approvalAgree: 'approvalAgree',
    approvalReject: 'approvalRejct',
    approvalTime: 'approvalTime',
    // assignee: 'assignee',
    approvalParam: 'approvalParam',
    taskName: 'taskName',
    assignee: 'Approver',
    startTime: 'startTime',
    endTime: 'endTime',
    durationTime: 'durationTime',
    refreshSuccess: 'refreshSuccess',
    refressFail: 'refressFail',
    tip1: 'Please check this box before agreeing to the operation!',
    tip2: 'Please check and reject the operation!',
    viewInvoices: 'viewInvoices',
    print: 'print',
    approvalStatus: 'approvalStatus',
    tip44: 'Please fill in the approval opinion',
    initiationDate: 'Initiation date',
    save: 'save'
  },

  // 订单进度管理
  Order: {
    orderProgressTable: 'orderProgressTable',
    progress: 'progress',
    operation: 'operation',
    viewDetail: 'viewDetail',
    orderProgressChart: 'orderProgressChart',
    contractNumber: 'contractNumber',
    contractDate: 'contractDate',
    customerName: 'customerName',
    search: 'search',
    reset: 'reset',
    refresh: 'refresh',
    deliveryDate: 'deliveryDate',
    destinationPort: 'destinationPort',
    shipmentPort: 'shipmentPort',
    createBy: 'createBy',
    progress: 'progress',


    addNode: 'addNode',
    confirmNode: 'confirmNode',
    linkName: 'linkName',
    computeMode: 'computeMode',
    basisNode: 'basisNode',
    Days: 'Days',
    createDate: 'createDate',
    chargePersion: 'chargePersion',
    status: 'status',
    edit: 'edit',
    delete: 'delete',
    selectNode: 'selectNode',
    confirm: 'confirm',
    cancel: 'cancel',
    sendSuccess: 'sendSuccess',


    addNodes: 'addNodes',
    nodeName: 'nodeName',
    nodeEndCondition: 'nodeEndCondition',
    nodeEndConditionSql: 'nodeEndConditionSql',
    nodesName: 'nodesName',
    createByName: 'createByName',
    createTime: 'createTime',
    tip1: 'Are you sure you want to delete this node?',
    tip: 'Tip',
    saveSuccess: 'saveSuccess',
  },



  /* 左侧菜单 */
  Menu: {
    messageCenter: 'MessageCenter',
    nodeSettings: 'nodeSettings',
    businessFlowTracking: 'businessFlowTracking',
    SystemAdmin: 'SystemAdmin',
    home: 'home',
    ListSet: 'ListSet',
    FetchSet: 'FetchSet',
    HomeSet: 'HomeSet',
    ReportPermissSet: 'ReportPermissSet',
    dataDict: 'DataDict',
    category:'Category',
    menuAdmin: 'MenuAdmin',
    userAdmin: 'UserAdmin',
    userOnline: 'UserOnline',
    roleAdmin: 'RoleAdmin',
    deptAdmin: 'DeptAdmin',
    printAdmin: 'PrintAdmin',
    systemLog: 'SystemLog',
    termsTemplete: 'TermsTemplete',
    warningSet: 'WarningSet',

    fieldSet: 'FieldSet',
    dataDictSet: 'DataDictSet',
    fetchFieldSet: 'FetchFieldSet',
    sheetSet: 'SheetSet',
    formSet: 'FormSet',
    codeSet: 'CodeSet',
    editUser: 'EditUser',
    languageSet: 'LanguageSet',
    versionView: 'VersionView',
    persionCenter: 'PersionCenter',
    warnPermissSet: 'WarnPermissSet',
    warning: 'Warning',
    warningFieldSet: 'WarningFieldSet',


    // 流程菜单
    flowMenu: 'FlowMenu',
    flowDefinition: 'FlowDefinition',
    flowConfig: 'FlowConfig',

    flowModel: 'FlowModel',
    modelOnlineDesign: 'ModelOnlineDesign',
    addFlowConfig: 'AddFlowConfig',
    editFlowConfig: 'EditFlowConfig',
    programmeSet:'方案设置',

    // 审批管理
    approvalAdmin: 'ApprovalAdmin',
    peddingApproval: 'PeddingApproval',
    approval: 'Approval',
    Initiated: 'Initiated',
    CCRecord: 'CC record',

    // 订单进度管理
    orderProgressAdmin: 'OrderProgressAdmin',
    orderProgressList: 'OrderProgressList',
    orderNodes: 'OrderNodes',
    orderProgressDetail: 'OrderProgressDetail',
    statisticsReport: 'StatisticalReport'
  },
  // 嵌套BI系统
  Bisystem: {
    BiName: "Statistics"
  },
  StatisticalManagement: {
    StatisticalManagement: "Statistical Management",
  },
  // email
  Emails: {
    eName: 'EmailSystem',
    write: 'WriteEmail',
    contact: 'Contacts',
    inbox: 'Inbox',
    outbox: 'Outbox',
    drafts: 'Drafts',
    trash: 'Spam',
    folders: 'MyFolders',
    label: 'Email Label',
    settings: 'Email Settings',
    please: 'Please Login',
    login: 'Login',
    loginsuccess: 'Login Success',
    loginfailed: 'Login Failed',
    account: 'Account',
    password: 'Password',
    notnull: 'Account or password cannot be blank',
    cancel: 'Cancel',
    go: 'Go on',
    register: 'Register',
    regSuccess: 'Register Success',
    khname: 'Unit Name',
    copy: 'Copy Send',
    secret: 'Secret Send',
    addcopy: 'Add Copy Send',
    addsecret: 'Add Secret Send',
    delcopy: 'Delete Copy Send',
    delsecret: 'Delete Secret Send',
    addressee: 'Addressee',
    copyName: 'CC',
    linkTips: 'Liaison not found, please to add liaison at Contacts',
    theme: 'Theme',
    upload: 'Upload attachments',
    clearFiles: 'Clear list',
    numsTips: 'Number of attachments currently selected: ',
    uploadTips: 'Total list size: ',
    readReceipt: 'Read Receipt',
    Sender: 'Sender',
    pickSender: 'Select a sender',
    send: 'Send',
    save: 'Save',
    reset: 'Reset',
    addresseeEmpty: 'Addressee cannot be empty',
    themeEmpty: 'Theme cannot be empty',
    senderEmpty: 'Sender cannot be empty',
    senderNull: 'No sender yet, please go to Email Settings to add',
    textEmpty: 'Sending content cannot be empty',
    sendSuccess: 'Send successful',
    sendFail: 'Send failed',
    saveSuccess: 'Save successful',
    saveFail: 'Save failed',
    refresh: 'Refresh',
    themeKeyword: "Theme's keyword",
    contentKeyword: "Content's keyword",
    senderKeyword: 'Select sender',
    getAll: 'All',
    readStatus: 'Reading status',
    all: 'All',
    unread: 'Unread',
    read: 'Read',
    receive: 'Receive',
    delete: 'Delete',
    emailNums: 'Total email',
    emailUnread: 'Unread email',
    receiving: 'Receiving...',
    receiveFail: 'Receiving Failed',
    receiveSuccess: 'Receiving Success',
    emailDetails: 'Click to view email details',
    delTips: 'Please select before deleting',
    delSuccess: 'Deleted successfully',
    delFail: 'Deleted failed',
    contactsList: 'Contacts List',
    contactsManage: 'Contacts Manage',
    categoryManage: 'Category Manage',
    categoryName: 'Category Name',
    customerName: 'Customer Name',
    name: 'Name',
    customerCode: 'Customer Code',
    email: 'Email',
    operation: 'Operation',
    categoryParent: 'Category Parent',
    phone: 'Phone',
    reply: 'Reply',
    replyAll: 'Reply All',
    date: 'Date',
    saving: 'Save',
    pickCategory: 'Choose category',
    edit: 'Edit',
    cateTips: 'Please select one correctly',
    writeCategoryName: 'Fill in the category name',
    nameMailTips: 'Please complete your name and email address' ,
    recoverySuccess: 'Recovery successful',
    recoveryFail: 'Recovery Fail',
    newFolder: 'New folder',
    addFolderTips: 'Please enter a folder name',
    addSuccess: 'Successfully added',
    addFail: 'Failed to add',
    emailSetting: 'Mailbox settings',
    signSetting: 'Signature settings',
    folderSetting: 'Folder settings',
    displayName: 'Display Name',
    senderName: 'Sender Name',
    copyName: '抄送人',
    emailAddress: 'e-mail address',
    sendingServer: 'Sending server',
    port: 'Port',
    sendTime: 'Sending time',
    originalMail: 'Original mail',
    receiveAll: "Receive all accounts'email",
    receiveMailPick: 'Please select the account to be charged',
    receiveMailTime: 'Please select the time period to be charged',
    receiveEmail: 'Mail receiving',
    emailNums: 'Number of emails',
    relatedModules: 'Related modules',
    moduleName: 'moduleName',
    fno: 'fno',
    selectReportFile: 'selectReportFile',
    uploaded: 'Uploaded report attachments',
    uploadSuccess: 'Upload successful',
    uploadFailed: 'Upload failed',
    selectModelName: 'Please select module name', 
    selectPrintFormat: 'Please select a print format'  ,
    readTime: 'Read Time'  ,
    readAddress: 'Address'  ,
    readCount: 'Read Count',
    markAllAsRead: 'Mark all as read"',
    markSelectedAsRead: 'Mark selected as read',
    markAllAsReadTip: 'Are you sure you want to mark all emails as read',
    markSelectedAsReadtip: 'Are you sure you want to mark the selected emails as read',
    markedSuccess: 'Marked successfully',
    markedFail: 'Marking failed',
    moveto: 'Move to',
    emailForward: 'Forward',
    emailTemplate: 'Template',
    restore: 'Are you sure you want to recover this email',
    emailSet: 'MailboxConfiguration'
  }
}
