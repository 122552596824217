<template>
  <el-dropdown :trigger="trigger" @command="handleClick">
    <span class="el-dropdown-link">
      <slot />
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="item in dropdown"
        :command="item.command"
        :icon="item.icon"
        :divided="item.divided"
        :key="item.label"
        >{{ item.label }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "Y-dropDown",
  props: {
    dropdown: {
      type: Array,
      default: () => [],
    },
    trigger: {
      type: String,
      default: "hover",
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    handleClick(command) {
       this.$emit("clickDropdown", command)
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.el-dropdown {
  // width: 100%;
  .el-dropdown-link {
    // width: 100%;
    // height: 100%;
    display: inline-block;
  }
}
</style>
