var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { attrs: { separator: "/" } },
    [
      _c(
        "transition-group",
        { attrs: { name: "breadcrumb" } },
        _vm._l(_vm.navList, function(item, index) {
          return _c("el-breadcrumb-item", { key: item.path }, [
            _c("span", [_vm._v(_vm._s(item.meta.title))])
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }