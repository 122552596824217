var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "treeselect",
    _vm._b(
      {
        staticClass: "Y-treeSelect",
        style: _vm.style,
        attrs: {
          value: _vm.input,
          options: _vm.options && _vm.formatOptions(_vm.options),
          normalizer: _vm.normalizerFormat && _vm.normalizer,
          appendToBody: _vm.appendToBody,
          placeholder: _vm.placeholder
        },
        on: {
          input: function(value, instanceId) {
            return _vm.$emit("update:input", value)
          },
          select: _vm.selectDepart
        }
      },
      "treeselect",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }