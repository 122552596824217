var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "收件人" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "请选择",
                    multiple: "",
                    clearable: ""
                  },
                  model: {
                    value: _vm.form.tos,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "tos", $$v)
                    },
                    expression: "form.tos"
                  }
                },
                _vm._l(_vm.emailContactList, function(item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主题" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.form.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "subject", $$v)
                  },
                  expression: "form.subject"
                }
              })
            ],
            1
          ),
          _c("el-form-item", [
            _c("div", [
              _c("div", { staticClass: "fujian" }, [
                _c(
                  "a",
                  {
                    staticClass: "cb0 nui-txt-link",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.chooseFile }
                  },
                  [
                    _c("b", { staticClass: "nui-ico nui-ico-cmpAttach" }),
                    _vm._v("添加附件")
                  ]
                )
              ])
            ])
          ]),
          _c(
            "el-form-item",
            _vm._l(_vm.uploadFileList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "fitem",
                  staticStyle: { width: "292px" }
                },
                [
                  _c("b", {
                    staticClass: "nui-ico-file",
                    attrs: { id: "_mail_icon_48_302" }
                  }),
                  _c("div", { staticClass: "o0" }, [_vm._v(_vm._s(item.name))]),
                  _c("div", { staticClass: "m0" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nui-txt-link",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.delFile(item)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]),
                  _c("div", { staticClass: "j0" }, [
                    _vm._v(_vm._s(_vm.renderSize(item.size)))
                  ]),
                  _c("div", {
                    staticClass: "n0",
                    staticStyle: { display: "none" }
                  })
                ]
              )
            }),
            0
          ),
          _c("el-form-item", { attrs: { label: "内容" } }, [
            _c("div", { attrs: { id: "editor" } })
          ]),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("发送")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-upload", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "attachUpload",
        attrs: {
          "file-list": _vm.fileList,
          "auto-upload": false,
          "http-request": _vm.httpRequest,
          "on-change": _vm.attachUploadOnChange,
          action: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }