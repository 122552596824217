var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop_wrapper" },
    _vm._l(_vm.tableData, function(item, index) {
      return _c("div", { key: index, staticClass: "shop_item_bg" }, [
        item.img_ids
          ? _c("div", { staticClass: "shop_item" }, [
              _c("a", { staticClass: "shop_item_link", attrs: { href: "#" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      "flex-direction": "column"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "1",
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "rax-image",
                          staticStyle: {
                            width: "auto",
                            height: "auto",
                            "max-width": "100%",
                            "max-height": "100%"
                          },
                          attrs: { src: item.img_ids[0].url },
                          on: {
                            dblclick: function($event) {
                              return _vm.imgDbClick(item.id)
                            }
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "shop_main-wrapper",
                        staticStyle: { width: "100%", height: "30%" }
                      },
                      [
                        _c("span", { staticClass: "shop__main-title" }, [
                          _vm._v(_vm._s(item.spname))
                        ]),
                        _c("span", { staticClass: "shop__main-title" }, [
                          _vm._v(_vm._s(item.sdesc))
                        ])
                      ]
                    )
                  ]
                )
              ])
            ])
          : _c("div", { staticClass: "shop_item noimage" }, [
              _c("i", { staticClass: "iconfont icon-tupianguanli" }),
              _c("span", [_vm._v("暂无图片")])
            ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }