<template>
  <el-collapse :accordion="accordion" v-model="value">
    <el-collapse-item
      v-for="(item, index) in collapse"
      :title="item.title"
      :name="item.title"
      :key="`${item.title}-${index}`"
    >
      <slot :item="item" :$index="index"></slot>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: "Y-collapse",
  props: {
    collapse: {
      type: Array,
      default: () => [],
    },
    accordion: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const value = this.accordion ? "" : [];
    return {
      value,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>
