import request from "@/axios/index";
// 查询菜单
export function findmenuList(params) {
  return request({
    url: "/sys/menu",
    method: "get",
    params
  })
}
// 查询菜单(返回树结构)
export function findmenuTreeList(params) {
  return request({
    url: "/sys/menu/tree",
    method: "get",
    params
  })
}
// 查询菜单
export function findmenuLists(params) {
  return request({
    url: "/sys/menu/tree_list",
    method: "get",
    params
  })
}

// 新增菜单
export function addmenuLists(data) {
  return request({
    url: "/sys/menu",
    method: "post",
    data
  })
}
//获取一行菜单信息
export function getmenuInfo(id) {
  return request({
    url: `/sys/menu/${id}`,
    method: "get"
  })
}
// 修改菜单
export function editMenuInfo(data) {
  return request({
    url: `/sys/menu/${data.id}`,
    method: "put",
    data
  })
}
// 删除菜单信息
export function deletemenuInfo(id) {
  return request({
    url: `/sys/menu/${id}`,
    method: "delete"
  })
}
// 批量删除菜单
export function deleteAllmenuInfo(data) {
  return request({
    url: `/sys/menu/deleteAll`,
    method: "delete",
    data
  })
}
// 关联菜单
export function relevanceMenu(params) {
  return request({
    url: `/formConfig/selectRoots`,
    method: "get",
    params
  })
}

// 获取所有菜单
export function allMenus(params) {
  return request({
    url: `/getRouters`,
    method: "get",
    params
  })
}

// 添加最近点击的菜单记录
export function addNewClickMenuRecord(params) {
  return request({
    url: `/commonApplication`,
    method: "post",
    data:params
  })
}

// 用户管理数据转交
export function passOndata(params, data) {
  return request({
    url: `/custom/reassign_by_modal`,
    method: "post",
    params,
    data
  })
}

// 详情模块数据转交
export function passonsedata(params, data) {
  return request({
    url: `/custom/reassign_by_id`,
    method: "post",
    params,
    data
  })
}
