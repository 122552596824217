<template>
  <el-tree
    :data="data"
    show-checkbox
    :node-key="path"
    :default-checked-keys="checkKeys"
    :expand-on-click-node="false"
    :render-content="renderContent"
    ref="tree"
  >
  </el-tree>
</template>

<script>
export default {
  name: "Y-tree",
  props: {
    tree: {
      type: Array,
      required: true,
    },
    renderContent: Function,
    path: {
      type: String,
      default: () => {
        return 'path'
      }
    },
    checkKeys: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return { data: JSON.parse(JSON.stringify(this.tree)) };
  },
  computed: {},
  created() {
    // this.showItem();
  },
  mounted() {},
  watch: {
    tree:{
      handler(newVal){
        this.data = this._.cloneDeep(newVal)
      },
      deep:true
    },
  },
  methods: {
    // showItem() {
    //   let data = JSON.parse(JSON.stringify(this.tree));
    //   this.data = data;
    // },
  },
  components: {},
};
</script>

<style scoped lang="scss"></style>
