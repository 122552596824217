var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-date-picker",
        _vm._g(
          {
            staticStyle: { height: "100%" },
            attrs: {
              type: _vm.type,
              "picker-options": _vm.options,
              "range-separator": "至",
              "start-placeholder": this.placeholderspan + "起",
              "end-placeholder": this.placeholderspan + "终",
              size: "mini"
            },
            on: { change: _vm.datechange },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }