import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import actions from "./actions";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default{
    namespaced:true,
    getters,
    mutations,
    state,
    actions
}