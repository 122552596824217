var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        id: "Y-drawer",
        visible: _vm.drawer,
        direction: _vm.direction,
        "append-to-body": true,
        wrapperClosable: false,
        title: _vm.title,
        size: _vm.size
      },
      on: {
        close: function($event) {
          return _vm.$emit("update:drawer", false)
        }
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "title" }, slot: "title" },
        [_vm._t("title")],
        2
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }