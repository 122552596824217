/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
	const valid_map = ['admin', 'editor'];
	return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
	const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
	return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
	const reg = /^[a-z]+$/;
	return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
	const reg = /^[A-Z]+$/;
	return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
	const reg = /^[A-Za-z]+$/;
	return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
	const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
	if (typeof str === 'string' || str instanceof String) {
		return true;
	}
	return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
	if (typeof Array.isArray === 'undefined') {
		return Object.prototype.toString.call(arg) === '[object Array]';
	}
	return Array.isArray(arg);
}
/**
 * @param {Object} obj
 * @returns {Boolean}
 */
export function isObject(obj) {
	return Object.prototype.toString.call(obj) === '[object Object]';
}
/**
 * @param {null} null
 * @returns {Boolean}
 */
export function isNull(obj) {
	return Object.prototype.toString.call(obj) === '[object Null]';
}
/**
 * @param {Number} num
 * @returns {Boolean}
 */
export function isNum(num) {
	if (typeof num === 'number') {
		return true;
	}
	return false;
}
export function isHtmlElement(node) {
	return node && node.nodeType === Node.ELEMENT_NODE;
}

export const isFunction = functionToCheck => {
	var getType = {};
	return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
};

export const isUndefined = val => {
	return val === void 0;
};

export const isDefined = val => {
	return val !== undefined && val !== null;
};
// 返回项目路径
export function getNormalPath(p) {
	if (p.length === 0 || !p || p == 'undefined') {
		return p
	};
	let res = p.replace('//', '/')
	if (res[res.length - 1] === '/') {
		return res.slice(0, res.length - 1)
	}
	return res;
}
/**
 * 判断url是否是http或https 
 * @param {string} path
 * @returns {Boolean}
 */
export function isHttp(url) {
	return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1
}
