var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPaged
    ? _c("el-pagination", {
        class: {
          ypage: _vm.isSmall,
          ymiddle: _vm.isMiddle,
          ylarge: _vm.isLarge
        },
        attrs: {
          "current-page": _vm.currentPages,
          "page-sizes": [20, 50, 100, 200],
          "page-size": _vm.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
          "update:currentPage": function($event) {
            _vm.currentPages = $event
          },
          "update:current-page": function($event) {
            _vm.currentPages = $event
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }