import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

//导入语言包
import Zh from '../lang/zh.js'; // 中文语言包
import En from '../lang/en.js'; // 英文语言包

const messages = {
         zh: Zh, // 中文语言包
         en: En, // 英文语言包
}

export default new VueI18n({
    locale: 'zh', // set locale 默认显示中文
    fallbackLocale: 'en', //如果语言包没有，则默认从英语中抽取
    silentTranslationWarn: true,
    messages: messages // set locale messages
});