<template>
  <div>
    <el-table
      :data="tableData"
      :max-height="maxHeight"
      ref="Ytable"
      border
      size="mini"
      :highlight-current-row="true"
    >
      <el-table-column
        label="单选"
        width="60"
        align="center"
        header-align="center"
        fixed="left"
      >
        <template slot-scope="scope">
          <el-radio class="radio" v-model="radio" :label="scope.row.id" @change="roadeChange(scope.row)">
            <span></span>
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column
        label="行号"
        type="index"
        width="60"
        align="center"
        fixed="left"
        header-align="center"
      >
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columnList"
        :key="index"
        :prop="item.fieldName"
        :label="item.showName"
        sortable
        :min-width="item.width === 'auto' ? '' : item.width || item.colWidth || 120"
        :show-overflow-tooltip="item.fieldName != 'img_ids'"
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">
            {{
              item.dataType == 2 || item.dataType == 4 ? parseToThousandth(scope.row[item.fieldName], item.decimalDigit) : scope.row[item.fieldName]
            }}
            <!-- {{ scope.row[item.fieldName] | booleanFilter(item, fieldsVOs) }} -->
            </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import clone from "clone"
export default {
  name: "Y-radioTable",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: [String, Number]
    },
    column: {
      type: Array,
      default: () => [],
    },
    fieldsVOs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      radio: '',
      // tableColumn: []
    };
  },
  computed: {
    columnList(){
      return this.column.filter(item=>{
        return (item.hasOwnProperty('isShow') && item.isShow && item.showName !== "" && item.showName !== null && item.showName !== undefined) || !item.hasOwnProperty('isShow');
      })
    }
  },
  created() {
    // this.tableColumn = []
    // this.tableColumn = this.column.filter(function (item) {
    //   return item.isShow
    // })
  },
  watch: {
    tableData: {
      handler(newVal) {
      },
      deep: true,
      immediate: true,
    },
    // column: {
    //   handler(val) {
    //     this.tableColumn = []
    //     this.tableColumn = val.filter(function (item) {
    //       return item.isShow
    //     })
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  mounted() {
  },
  methods: {
    parseToThousandth(num, point) {
      let newnum = ""
      if (num === "" || num === undefined || num === null) {
        newnum = ""
      } else {
        newnum = num + ""
      }
      if (point && point != 0) {
        let [sInt, sFloat] = (Number.isInteger(newnum) ? `${newnum}` : Number(newnum)?.toFixed(point) + "").split('.');
        sInt = sInt.replace(/\d(?=(\d{3})+$)/g, '$&,');
        return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
      } else {
        let [sInt, sFloat] = (Number.isInteger(newnum) ? `${newnum}` : newnum).split('.');
        sInt = sInt.replace(/\d(?=(\d{3})+$)/g, '$&,');
        return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
      }
    },
    roadeChange (row) {
      this.$emit("roadeChange", row)
    },
  },
  components: {},
  destroyed() {
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-table th, .el-table th.is-leaf {
  background: rgb(218, 219, 221)!important;
}
</style>
